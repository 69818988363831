import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import FormFooter from '../Form/FormFooter';
import SelectInput from '../../Common/Form/SelectInput';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import MobxForm from '../../MobxForm/MobxForm';
import MobxFieldGroup from '../../MobxForm/MobxFieldGroup';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';
import PostcodeLookup from '../PostcodeLookup/PostcodeLookup';
import YearsMonthsField from '../Form/YearsMonthsField';
import ApplicationCard from '../../Application/components/ApplicationCard';
import MiniPanel from '../MiniPanel';
import MiniPanelHeader from '../MiniPanelHeader';
import ExclamationWarningWithButton from '../ExclamationWarningWithButton';
import TextInputWrappingInput from '../Form/TextInputWrappingInput';
import Button from '../Button/Button';
import ExclamationWarning from '../ExclamationWarning';
import './addressHistoryForm.scss';

class AddressHistoryForm extends React.Component {
  UNSAFE_componentWillMount() {
    const defaultValues = this.props.defaultValues;
    this.props.appStore.customerHistoryStore.setupValidation(this.props.validationRules.Items[0]);
    this.props.appStore.customerHistoryStore.setInitialValues('AddressHistory', defaultValues);
    this.props.appStore.customerHistoryStore.setApplication(this.props.application);
    this.props.progress.address < 100
      ? this.props.appStore.customerHistoryStore.handleEditAddress(0)
      : this.props.appStore.customerHistoryStore.setEditingFalse();
  }

  componentDidUpdate(prevProps) {
    if (this.props.validationRules !== prevProps.validationRules) {
      this.props.appStore.customerHistoryStore.setupValidation(this.props.validationRules.Items);
    }
  }

  onSave = () => {
    this.props.appStore.customerHistoryStore.checkWarnings();

    if (this.props.appStore.customerHistoryStore.showTimeAtAddressDOBWarning === false) {
      this.props.onSave(toJS(this.props.appStore.customerHistoryStore.formData));
    }
  };
  submitForm = () => {
    this.props.appStore.customerHistoryStore.checkWarnings();

    if (
      !this.props.appStore.customerHistoryStore.errorCount &&
      this.props.appStore.customerHistoryStore.showTimeAtAddressWarning === false &&
      this.props.appStore.customerHistoryStore.showTimeAtAddressDOBWarning === false
    ) {
      this.props.onSubmit(toJS(this.props.appStore.customerHistoryStore.formData));
    }

    this.props.appStore.customerHistoryStore.setErrorsToState();
  };

  render() {
    const errors = this.props.appStore.customerHistoryStore.validator.getErrors();
    let formData = this.props.appStore.customerHistoryStore.formData;
    let customerHistoryStore = this.props.appStore.customerHistoryStore;
    return (
      <MobxForm onSubmit={this.submitForm}>
        {formData.Items.map((value, index) => {
          let totalMonths = 0;

          if (!value.TimeAtAddress.TotalMonths) {
            totalMonths = value.TimeAtAddress.Years * 12 + value.TimeAtAddress.Months;
          } else {
            totalMonths = value.TimeAtAddress.TotalMonths;
          }

          return (
            <MiniPanel key={'mini_' + index}>
              <MiniPanelHeader title={`${this.props.t('PrintedAddressHistory.address')} ${index + 1}`} />

              {value.isEditing === true ? (
                <div className="addressHistoryForm" data-th="AddressHistoryForm">
                  <PostcodeLookup
                    inline
                    id="PostcodeLookup"
                    addressType="residential"
                    onSelection={(val) => customerHistoryStore.handlePostCodeLookup(val, index)}
                    dealershipId={this.props.dealershipId}
                    quoteId={this.props.quoteId}
                  />
                  <MobxFieldGroup
                    isInline
                    isFormSubmitted={customerHistoryStore.isFormSubmitted}
                    error={errors.BuildingName}
                  >
                    <MobxFormLabel>
                      <div>{this.props.t('AddressHistoryForm.building_name')}</div>
                    </MobxFormLabel>
                    <TextInputWrappingInput
                      id="BuildingName"
                      value={value.BuildingName}
                      onChange={(val) => customerHistoryStore.handleFieldChange('BuildingName', val, index)}
                    />
                  </MobxFieldGroup>
                  <MobxFieldGroup
                    isInline
                    isFormSubmitted={customerHistoryStore.isFormSubmitted}
                    error={errors.BuildingNumber}
                  >
                    <MobxFormLabel>
                      <div>{this.props.t('AddressHistoryForm.building_number')}</div>
                    </MobxFormLabel>
                    <TextInputWrappingInput
                      id="BuildingNumber"
                      value={value.BuildingNumber}
                      onChange={(val) => customerHistoryStore.handleFieldChange('BuildingNumber', val, index)}
                    />
                  </MobxFieldGroup>
                  <MobxFieldGroup
                    isInline
                    isFormSubmitted={customerHistoryStore.isFormSubmitted}
                    error={errors.SubBuilding}
                  >
                    <MobxFormLabel>
                      <div>{this.props.t('AddressHistoryForm.room_floor')}</div>
                    </MobxFormLabel>
                    <TextInputWrappingInput
                      id="SubBuilding"
                      value={value.SubBuilding}
                      onChange={(val) => customerHistoryStore.handleFieldChange('SubBuilding', val, index)}
                    />
                  </MobxFieldGroup>
                  <MobxFieldGroup isInline isFormSubmitted={customerHistoryStore.isFormSubmitted} error={errors.Street}>
                    <MobxFormLabel>
                      <div>{this.props.t('AddressHistoryForm.street')}</div>
                    </MobxFormLabel>
                    <TextInputWrappingInput
                      id="Street"
                      value={value.Street}
                      onChange={(val) => customerHistoryStore.handleFieldChange('Street', val, index)}
                    />
                  </MobxFieldGroup>
                  <MobxFieldGroup
                    isInline
                    isFormSubmitted={customerHistoryStore.isFormSubmitted}
                    error={errors.District}
                  >
                    <MobxFormLabel>
                      <div>{this.props.t('AddressHistoryForm.district')}</div>
                    </MobxFormLabel>
                    <TextInputWrappingInput
                      id="District"
                      value={value.District}
                      onChange={(val) => customerHistoryStore.handleFieldChange('District', val, index)}
                    />
                  </MobxFieldGroup>
                  <MobxFieldGroup
                    isInline
                    isFormSubmitted={customerHistoryStore.isFormSubmitted}
                    error={errors.PostTown}
                  >
                    <MobxFormLabel>
                      <div>{this.props.t('AddressHistoryForm.city_town')}</div>
                    </MobxFormLabel>
                    <TextInputWrappingInput
                      id="PostTown"
                      value={value.PostTown}
                      onChange={(val) => customerHistoryStore.handleFieldChange('PostTown', val, index)}
                    />
                  </MobxFieldGroup>
                  <MobxFieldGroup
                    isInline
                    isFormSubmitted={customerHistoryStore.isFormSubmitted}
                    error={errors.Postcode}
                  >
                    <MobxFormLabel>
                      <div>{this.props.t('AddressHistoryForm.postcode')}</div>
                    </MobxFormLabel>
                    <TextInputWrappingInput
                      id="Postcode"
                      value={value.Postcode}
                      onChange={(val) => customerHistoryStore.handleFieldChange('Postcode', val, index)}
                    />
                  </MobxFieldGroup>
                  <MobxFieldGroup
                    isInline
                    isFormSubmitted={customerHistoryStore.isFormSubmitted}
                    error={errors.Residency}
                  >
                    <MobxFormLabel>
                      <div>{this.props.t('AddressHistoryForm.residency')}</div>
                    </MobxFormLabel>
                    <SelectInput
                      id="Residency"
                      dataThook="Residency"
                      options="Residency"
                      value={value.Residency}
                      onChange={(id, val) => customerHistoryStore.handleFieldChange(id, val, index)}
                    />
                  </MobxFieldGroup>
                  <MobxFieldGroup
                    isInline
                    isFormSubmitted={customerHistoryStore.isFormSubmitted}
                    error={errors.TimeAtAddress && errors.TimeAtAddress.TotalMonths}
                  >
                    <MobxFormLabel>
                      <div>{this.props.t('AddressHistoryForm.time_at_address')}</div>
                    </MobxFormLabel>
                    <YearsMonthsField
                      id="TotalMonths"
                      name="TotalMonths"
                      value={totalMonths}
                      onChange={(val) => customerHistoryStore.handleFieldChangeYM(val, index, 'TimeAtAddress')}
                    />
                  </MobxFieldGroup>
                  <div className="addressHistoryForm__removeOuter">
                    <div className="addressHistoryForm__remove">
                      {formData.Items.length > 1 && (
                        <Button
                          type="button"
                          buttonStyle="cancel"
                          size="small"
                          key={'remove_' + index}
                          onClick={() => customerHistoryStore.handleRemoveAddress(index, 'TimeAtEmployment')}
                        >
                          {this.props.t('AddressHistoryForm.remove')}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <ApplicationCard
                  type="address"
                  data={value}
                  edit={() => customerHistoryStore.handleEditAddress(index)}
                  remove={() => customerHistoryStore.handleRemoveAddress(index, 'TimeAtAddress')}
                  showRemove={formData.Items.length > 1 ? true : false}
                  errorCount={customerHistoryStore.countErrors(index)}
                  submitted={customerHistoryStore.formSubmitted}
                  key={'card' + index}
                />
              )}
            </MiniPanel>
          );
        })}

        {customerHistoryStore.showTimeAtAddressWarning && (
          <ExclamationWarningWithButton
            title={this.props.t('AddressHistoryForm.thank_you')}
            message={this.props.t('AddressHistoryForm.years_of_address_history')}
            onClick={() => customerHistoryStore.addItem('Address')}
            buttonText={this.props.t('AddressHistoryForm.add_new_address')}
          />
        )}

        {customerHistoryStore.showTimeAtAddressDOBWarning && (
          <ExclamationWarning message={this.props.t('AddressHistoryForm.address_history_starts_prior_to_dob')} />
        )}

        <FormFooter
          onSave={this.props.onSave ? this.onSave : null}
          submittingState={this.props.submittingState}
          savingState={this.props.savingState}
        />
      </MobxForm>
    );
  }
}

AddressHistoryForm.propTypes = {
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  validationRules: PropTypes.object.isRequired,
  savingState: PropTypes.string,
  submittingState: PropTypes.string,
  dealershipId: PropTypes.string,
  quoteId: PropTypes.string,
  application: PropTypes.object
};
export default withTranslation('Common')(inject('appStore')(observer(AddressHistoryForm)));
