import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SelectedLabel from './SelectedLabel';
import Icon from './Icon/Icon';
import './visVehicleCard.scss';

class VisVehicleCard extends Component {
  constructor() {
    super();
    this.state = {
      isHovering: false
    };
  }

  handleClick = () => {
    this.props.onClick(this.props.vehicle);
  };

  handleIconName = (vehicleClass) => {
    if (vehicleClass === 'LCV') {
      return 'van';
    } else {
      return vehicleClass.toLowerCase();
    }
  };

  render() {
    const { vehicle, isSelected } = this.props;
    let fuelType = vehicle.FuelType && vehicle.FuelType.replace('/', ' / ');
    let generation = vehicle.Generation && vehicle.Generation.replace('(', ' (');

    return (
      <div
        data-th="visVehicleCard"
        className={`visVehicleCard__container${isSelected ? '--selected' : ''}`}
        onClick={this.handleClick}
      >
        <div className="visVehicleCard__top">
          <div className="visVehicleCard__vehicleIconContainer">
            <div className="visVehicleCard__vehicleIcon">
              <Icon name={this.handleIconName(vehicle.Class)} />
            </div>
            <div data-th="makeModel" className="visVehicleCard__vehicleIconText">
              <strong>{`${vehicle.Make} ${vehicle.Model}`}</strong>
            </div>
            <span data-th="derivative" className="visVehicleCard__derivativeTitle">
              {vehicle.Derivative || '-'}
            </span>
          </div>

          {isSelected && <SelectedLabel showRemove />}
        </div>

        <div className="visVehicleCard__bottom">
          <div className="visVehicleCard__bottomSection">
            <span className="visVehicleCard__vehicleValues">{fuelType || '-'}</span>
          </div>
          <div className="visVehicleCard__bottomSection">
            <span data-th="doors" className="visVehicleCard__vehicleValues">
              {vehicle.Doors || '-'} {this.props.t('VisVehicleCard.doors')}
            </span>
          </div>
          <div className="visVehicleCard__bottomSection">
            <span data-th="transmission" className="visVehicleCard__vehicleValues">
              {vehicle.Transmission || '-'}
            </span>
          </div>
          <div className="visVehicleCard__bottomSection">
            <span data-th="generation" className="visVehicleCard__vehicleValues">
              {generation || '-'}
            </span>
          </div>
          <div className="visVehicleCard__bottomSection">
            <span data-th="generation" className="visVehicleCard__vehicleValues">
              {vehicle.EnginePowerBHP || '-'} {this.props.t('VisVehicleCard.bhp')}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

VisVehicleCard.propTypes = {
  vehicle: PropTypes.object,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func
};

export default withTranslation('Common')(VisVehicleCard);
