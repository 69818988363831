/* eslint-disable import/no-anonymous-default-export */
import { PartialDeep } from 'type-fest';
import sharedDefaultRules from '../default';

export type LenderConsumerConfig = {
  applicationStatuses: PartialDeep<typeof sharedDefaultRules['applicationStatuses']> &
    Record<
      string,
      {
        isComplete: boolean;
        isEditable: boolean;
        isError: boolean;
        isPaidOut: boolean;
        isPending: boolean;
        isReproposable: boolean;
        isSubmitting: boolean;
        [key: string]: unknown;
      }
    >;
  editSections: {
    vehicle: boolean;
    quote: boolean;
    employment: boolean;
    affordability: boolean;
    bankDetails: boolean;
  };
  applicationFields: PartialDeep<typeof defaultConsumerRules['applicationFields'], { recurseIntoArrays: true }>;
  validationRules: PartialDeep<typeof defaultConsumerRules['validationRules'], { recurseIntoArrays: true }>;
};

const defaultConsumerRules = {
  applicationStatuses: sharedDefaultRules.applicationStatuses,

  editSections: sharedDefaultRules.editSections,

  applicationFields: {
    PersonalDetails: {
      Title: true,
      FirstName: true,
      MiddleNames: true,
      LastName: true,
      Email: true,
      HomeTelephone: true,
      MobileTelephone: true,
      Gender: true,
      DateOfBirth: true,
      MaritalStatus: true,
      NoOfDependants: true,
      DrivingLicence: true,
      Nationality: true,
      CountryOfBirth: false,
      DualNationality: false,
      DrivingLicenceNumber: false,
      BornUK: false,
      BirthPlace: false,
      Director: false,
      CountryOfOperation: false,
      CompanySector: false
    },
    AddressHistory: {
      BuildingName: true,
      PostcodeLookup: true,
      SubBuilding: true,
      BuildingNumber: true,
      Postcode: true,
      PostTown: true,
      Street: true,
      District: true,
      Residency: true,
      TimeAtAddress: true
    },
    EmploymentHistory: {
      Employer: true,
      Occupation: true,
      OccupationBasis: true,
      OccupationType: true,
      EmploymentSector: false,
      PostcodeLookup: true,
      SubBuilding: true,
      District: true,
      BuildingName: true,
      BuildingNumber: true,
      Street: true,
      PostTown: true,
      Postcode: true,
      TelephoneNumber: true,
      TimeAtEmployment: true,
      GrossAnnual: false
    },
    BankDetails: {
      AccountName: true,
      SortCode: true,
      AccountNumber: true,
      TimeAtBank: true
    },
    AffordabilityDetails: {
      GrossAnnual: true, // Gross Annual Income
      HasOtherHouseholdIncome: false, // Do you have any other household income?
      OtherHouseholdIncome: false, // What is your other household income before tax and other deductions per year?
      ForeseeFutureDownturnInFinances: true, // Do you foresee a downturn in your financial position...
      DownturnReason: true, // (if yes) What is the reason for the downturn?
      IsReplacementLoan: true, // Is this a replacement loan?
      DetailsOfReplacementLoan: true, // (if yes) What is your current monthly payment on this loan?
      ForeseenYearlyIncome: false, // (if yes) What do you expect your reduced annual income to be before tax and other deductions?
      MortgageOrRental: false, // Your share of monthly mortgage or rental expenditure
      Other: false, // Other committed monthly outgoings which are not debt related
      ChangesExpected: false, // Are there any expected changes in your personal circumstances...
      NetMonthly: true, // Typical net monthly take-home pay
      DetailsOfExpectedChanges: false // (if yes) What are the expected changes?"
    }
  },
  validationRules: {
    PersonalDetails: {
      Title: 'required, title:Gender',
      FirstName: 'required, doesNotStartOrEndWithSpace',
      MiddleNames: 'optional, doesNotStartOrEndWithSpace',
      LastName: 'required, doesNotStartOrEndWithSpace',
      Email: 'required, email, maxlength:100',
      HomeTelephone: 'required:unless:MobileTelephone, landline',
      MobileTelephone: 'required:unless:HomeTelephone, mobile',
      Gender: 'required, gender:Title',
      DateOfBirth: 'required, date, youngerThan99, olderThan18',
      MaritalStatus: 'required',
      NoOfDependants: 'required',
      DrivingLicence: 'required',
      CountryOfBirth: {
        BornUK: 'optional',
        BirthPlace: 'optional'
      },
      DirectorOrBoardMember: {
        Director: 'optional',
        CountryOfOperation: 'optional',
        CompanySector: 'optional'
      },
      Nationality: 'optional',
      DualNationality: 'optional',
      DrivingLicenceNumber: 'optional'
    },
    AddressHistory: {
      Items: [
        {
          BuildingName: 'required:unless:BuildingNumber',
          BuildingNumber: 'required:unless:BuildingName',
          Postcode: 'required, alphaNumSpace',
          PostTown: 'required, lenderAlphanumeric',
          Street: 'required, lenderAlphanumeric',
          District: 'optional, lenderAlphanumeric',
          Residency: 'required',
          TimeAtAddress: {
            Years: 'required, number:int, maxlength:3, max:100',
            Months: 'required, number:int, max:12, maxlength:2',
            TotalMonths: 'required, number:int, timeAtAddressMin:1, timeAtAddressMax:1188'
          }
        }
      ],
      totalMonths: 'min:36'
    },
    EmploymentHistory: {
      Items: [
        {
          Employer: 'required',
          Occupation: 'required',
          OccupationBasis: 'required',
          OccupationType: 'required',
          BuildingName: 'required:unless:BuildingNumber',
          BuildingNumber: 'required:unless:BuildingName',
          Street: 'required, lenderAlphanumeric',
          District: 'lenderAlphanumeric',
          PostTown: 'required, lenderAlphanumeric',
          Postcode: 'required',
          TelephoneNumber: 'required, businessPhone',
          EmploymentSector: 'optional',
          TimeAtEmployment: {
            Years: 'required, number:int, maxlength:3, max:100',
            Months: 'required, number:int, max:12, maxlength:2',
            TotalMonths: 'required, number:int, timeAtAddressMin:1, timeAtAddressMax:1188'
          },
          GrossAnnual: 'optional'
        }
      ],
      totalMonths: 'optional'
    },
    BankDetails: {
      AccountName: 'required',
      SortCode: 'required, sortCode',
      AccountNumber: 'required, accountNumber',
      TimeAtBank: {
        Years: 'required, number:int, max:100, maxlength:3',
        Months: 'required, number:int, max:12, maxlength:2',
        TotalMonths: 'required, number:int, min:0,  timeAtAddressMax:1188'
      }
    },
    AffordabilityDetails: {
      Income: {
        GrossAnnual: 'required, currency',
        NetMonthly: 'required, currency',
        HasOtherHouseholdIncome: 'optional',
        OtherHouseholdIncome: 'required:iftrue:HasOtherHouseholdIncome, currency'
      },
      PersonalCircumstances: {
        ForeseeFutureDownturnInFinances: 'required',
        DownturnReason: 'required:iftrue:ForeseeFutureDownturnInFinances',
        ChangesExpected: 'optional',
        DetailsOfExpectedChanges: 'optional',
        ForeseenYearlyIncome: 'optional, currency'
      },
      ReplacementLoan: {
        IsReplacementLoan: 'required',
        DetailsOfReplacementLoan: 'required:iftrue:IsReplacementLoan, currency'
      },
      MonthlyExpenditure: {
        MortgageOrRental: 'optional',
        Other: 'optional'
      }
    }
  }
};

export default defaultConsumerRules;
