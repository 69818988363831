import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import FormFooter from '../../Common/Form/FormFooter';
import ModalGross from '../modals/ModalGross';
import ModalReplacement from '../modals/ModalReplacement';
import ModalExpectedChanges from '../modals/ModalExpectedChanges';
import ModalYourShare from '../modals/ModalYourShare';
import ModalOtherOutgoings from '../modals/ModalOtherOutgoings';
import ModalOtherHouseholdIncome from '../modals/ModalOtherHouseholdIncome';
import Modal from '../../Common/Modal/Modal';
import CurrencyInput from '../../Common/Form/CurrencyInput';
import SelectInput from '../../Common/Form/SelectInput';
import MobxFieldGroup from '../../MobxForm/MobxFieldGroup';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';
import TextInputWrappingInput from '../../Common/Form/TextInputWrappingInput';
import MobxForm from '../../MobxForm/MobxForm';
import ButtonInfoIcon from '../../Common/Button/ButtonInfoIcon';
import Validator from '../../../validators/Validator';
import set from 'lodash/set';
import clone from 'lodash/set';
import YesNoInput from '~/components/Common/Form/YesNoInput';
import { enableYesNoAffordabilityQuestions, isMOTQuestionsOn } from '~/features';
import { inject } from 'mobx-react';

class AffordabilityForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: '',
      formData: {}
    };
  }

  UNSAFE_componentWillMount() {
    this.setUpValidation(this.props.validationRules);
    this.setInitialFormData(this.props.defaultValues);
  }

  setUpValidation(validationRules) {
    this.validator = new Validator();
    this.validator.setRules(validationRules);
  }

  setInitialFormData = (data) => {
    this.setState(() => {
      this.validator.validate(data);
      return {
        formData: data
      };
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.validationRules !== prevProps.validationRules) {
      this.setUpValidation(this.props.validationRules);
    }
  }

  handleFieldChange = (id, value) => {
    const currentState = clone(this.state.formData);
    const newState = set(currentState, id, value);
    this.validator.validate(newState);
    this.setState({
      formData: newState
    });
  };
  onSave = () => {
    this.props.onSave(this.state.formData);
  };
  submitForm = () => {
    !this.validator.errorCount && this.props.onSubmit(this.state.formData);
  };
  openModal = (name) => {
    return () => {
      this.setState({
        modalOpen: name
      });
    };
  };
  closeModal = () => {
    this.setState({
      modalOpen: ''
    });
  };

  render() {
    const errors = this.validator.getErrors();
    const applicationFields = this.props.applicationFields;

    // Enable specific affordability questions, just for MNF if a feature switch is enabled
    if (isMOTQuestionsOn() && this.props.funderCode === 'MOT') {
      applicationFields.MortgageOrRental = true;
      applicationFields.Other = true;
      applicationFields.NetMonthly = true;
    }

    return (
      <MobxForm onSubmit={this.submitForm}>
        {applicationFields.GrossAnnual && (
          <MobxFieldGroup isInline error={errors.Income && errors.Income.GrossAnnual}>
            <MobxFormLabel>
              <div>
                {this.props.t('AffordabilityForm.gross_annual_income')}{' '}
                <ButtonInfoIcon onClick={this.openModal('modalGross')} />
              </div>
            </MobxFormLabel>
            <CurrencyInput
              id="Income.GrossAnnual"
              dataThook="Income.GrossAnnual"
              value={this.state.formData.Income.GrossAnnual}
              onChange={(val) => this.handleFieldChange('Income.GrossAnnual', val)}
            />
          </MobxFieldGroup>
        )}

        {applicationFields.NetMonthly && (
          <MobxFieldGroup isInline error={errors.Income && errors.Income.NetMonthly}>
            <MobxFormLabel>{this.props.t('AffordabilityForm.typical_net_monthly_take_home_pay')}</MobxFormLabel>
            <CurrencyInput
              id="Income.NetMonthly"
              dataThook="Income.NetMonthly"
              value={this.state.formData.Income.NetMonthly}
              onChange={(val) => this.handleFieldChange('Income.NetMonthly', val)}
            />
          </MobxFieldGroup>
        )}

        {applicationFields?.HasOtherHouseholdIncome && (
          <MobxFieldGroup isInline error={errors.Income && errors.Income.HasOtherHouseholdIncome}>
            <MobxFormLabel>
              <div>
                {this.props.t('AffordabilityForm.other_household_income')}
                <ButtonInfoIcon onClick={this.openModal('modalHasOtherHouseholdIncome')} />
              </div>
            </MobxFormLabel>
            {enableYesNoAffordabilityQuestions() ? (
              <YesNoInput
                id="Income.HasOtherHouseholdIncome"
                value={this.state.formData.Income.HasOtherHouseholdIncome}
                onChange={this.handleFieldChange}
              />
            ) : (
              <SelectInput
                id="Income.HasOtherHouseholdIncome"
                dataThook="Income.HasOtherHouseholdIncome"
                type="select"
                options="YesOrNo"
                value={this.state.formData.Income.HasOtherHouseholdIncome}
                onChange={this.handleFieldChange}
              />
            )}
          </MobxFieldGroup>
        )}

        {applicationFields?.OtherHouseholdIncome &&
          this.state.formData?.Income?.HasOtherHouseholdIncome?.toString() === 'true' && (
            <MobxFieldGroup isInline error={errors.Income && errors.Income.OtherHouseholdIncome}>
              <MobxFormLabel>
                <div>{this.props.t('AffordabilityForm.what_is_your_other_household_income')}</div>
              </MobxFormLabel>
              <CurrencyInput
                id="Income.OtherHouseholdIncome"
                dataThook="Income.OtherHouseholdIncome"
                value={this.state.formData.Income.OtherHouseholdIncome}
                onChange={(val) => this.handleFieldChange('Income.OtherHouseholdIncome', val)}
              />
            </MobxFieldGroup>
          )}

        {applicationFields.IsReplacementLoan && (
          <MobxFieldGroup isInline error={errors.ReplacementLoan && errors.ReplacementLoan.IsReplacementLoan}>
            <MobxFormLabel>
              <div>
                {this.props.t('AffordabilityForm.is_this_a_replacement_loan')}{' '}
                <ButtonInfoIcon onClick={this.openModal('modalReplacement')} />
              </div>
            </MobxFormLabel>
            {enableYesNoAffordabilityQuestions() ? (
              <YesNoInput
                id="ReplacementLoan.IsReplacementLoan"
                value={this.state.formData.ReplacementLoan.IsReplacementLoan}
                onChange={this.handleFieldChange}
              />
            ) : (
              <SelectInput
                id="ReplacementLoan.IsReplacementLoan"
                dataThook="ReplacementLoan.IsReplacementLoan"
                type="select"
                options="YesOrNo"
                value={this.state.formData.ReplacementLoan.IsReplacementLoan}
                onChange={this.handleFieldChange}
              />
            )}
          </MobxFieldGroup>
        )}

        {applicationFields.IsReplacementLoan &&
          applicationFields.DetailsOfReplacementLoan &&
          this.state.formData.ReplacementLoan &&
          this.state.formData.ReplacementLoan.IsReplacementLoan &&
          this.state.formData.ReplacementLoan.IsReplacementLoan.toString() === 'true' && (
            <MobxFieldGroup isInline error={errors.ReplacementLoan && errors.ReplacementLoan.DetailsOfReplacementLoan}>
              <MobxFormLabel>
                {this.props.t('AffordabilityForm.if_yes_what_is_your_current_monthly_payment_on_this_loan')}
              </MobxFormLabel>
              <CurrencyInput
                id="ReplacementLoan.DetailsOfReplacementLoan"
                dataThook="ReplacementLoan.DetailsOfReplacementLoan"
                value={this.state.formData.ReplacementLoan.DetailsOfReplacementLoan}
                onChange={(val) => this.handleFieldChange('ReplacementLoan.DetailsOfReplacementLoan', val)}
              />
            </MobxFieldGroup>
          )}

        {applicationFields.ForeseeFutureDownturnInFinances && (
          <MobxFieldGroup
            isInline
            error={errors.PersonalCircumstances && errors.PersonalCircumstances.ForeseeFutureDownturnInFinances}
          >
            <MobxFormLabel>
              <div>
                {this.props.t('AffordabilityForm.foresee_future_downturn_in_finances')}
                <ButtonInfoIcon onClick={this.openModal('modalExpectedChanges')} />
              </div>
            </MobxFormLabel>
            {enableYesNoAffordabilityQuestions() ? (
              <YesNoInput
                id="PersonalCircumstances.ForeseeFutureDownturnInFinances"
                value={this.state.formData.PersonalCircumstances.ForeseeFutureDownturnInFinances}
                onChange={this.handleFieldChange}
              />
            ) : (
              <SelectInput
                id="PersonalCircumstances.ForeseeFutureDownturnInFinances"
                dataThook="PersonalCircumstances.ForeseeFutureDownturnInFinances"
                options="YesOrNo"
                value={this.state.formData.PersonalCircumstances.ForeseeFutureDownturnInFinances}
                onChange={this.handleFieldChange}
              />
            )}
          </MobxFieldGroup>
        )}

        {applicationFields.ForeseeFutureDownturnInFinances &&
          this.state.formData.PersonalCircumstances &&
          this.state.formData.PersonalCircumstances.ForeseeFutureDownturnInFinances &&
          this.state.formData.PersonalCircumstances.ForeseeFutureDownturnInFinances.toString() === 'true' && (
            <>
              <MobxFieldGroup
                isInline
                error={errors.PersonalCircumstances && errors.PersonalCircumstances.DownturnReason}
              >
                <MobxFormLabel>
                  {this.props.t('AffordabilityForm.if_yes_what_is_the_reason_for_the_downturn')}
                </MobxFormLabel>
                <TextInputWrappingInput
                  id="PersonalCircumstances.DownturnReason"
                  dataThook="PersonalCircumstances.DownturnReason"
                  value={this.state.formData.PersonalCircumstances.DownturnReason}
                  onChange={(val) => this.handleFieldChange('PersonalCircumstances.DownturnReason', val)}
                />
              </MobxFieldGroup>
              {applicationFields.ForeseenYearlyIncome && (
                <MobxFieldGroup
                  isInline
                  error={errors.PersonalCircumstances && errors.PersonalCircumstances.ForeseenYearlyIncome}
                >
                  <MobxFormLabel>
                    {this.props.t('AffordabilityForm.if_yes_expect_your_reduced_annual_income')}
                  </MobxFormLabel>
                  <CurrencyInput
                    id="PersonalCircumstances.ForeseenYearlyIncome"
                    dataThook="PersonalCircumstances.ForeseenYearlyIncome"
                    value={this.state.formData.PersonalCircumstances.ForeseenYearlyIncome}
                    onChange={(val) => this.handleFieldChange('PersonalCircumstances.ForeseenYearlyIncome', val)}
                  />
                </MobxFieldGroup>
              )}
            </>
          )}

        {applicationFields.MortgageOrRental && (
          <MobxFieldGroup isInline error={errors.MonthlyExpenditure && errors.MonthlyExpenditure.MortgageOrRental}>
            <MobxFormLabel>
              <div>
                {this.props.t('AffordabilityForm.your_share_of_monthly_mortgage_or_rental_expenditure')}{' '}
                <ButtonInfoIcon onClick={this.openModal('modalYourShare')} />
              </div>
            </MobxFormLabel>
            <CurrencyInput
              id="MonthlyExpenditure.MortgageOrRental"
              dataThook="MonthlyExpenditure.MortgageOrRental"
              value={this.state.formData.MonthlyExpenditure.MortgageOrRental}
              onChange={(val) => this.handleFieldChange('MonthlyExpenditure.MortgageOrRental', val)}
            />
          </MobxFieldGroup>
        )}

        {applicationFields.Other && (
          <MobxFieldGroup isInline error={errors.MonthlyExpenditure && errors.MonthlyExpenditure.Other}>
            <MobxFormLabel>
              <div>
                {this.props.t('AffordabilityForm.other_committed_monthly_outgoings_which_are_not_debt_related')}{' '}
                <ButtonInfoIcon onClick={this.openModal('modalOtherOutgoings')} />
              </div>
            </MobxFormLabel>
            <CurrencyInput
              id="MonthlyExpenditure.Other"
              dataThook="MonthlyExpenditure.Other"
              value={this.state.formData.MonthlyExpenditure.Other}
              onChange={(val) => this.handleFieldChange('MonthlyExpenditure.Other', val)}
            />
          </MobxFieldGroup>
        )}

        {applicationFields.ChangesExpected && (
          <MobxFieldGroup isInline error={errors.PersonalCircumstances && errors.PersonalCircumstances.ChangesExpected}>
            <MobxFormLabel>
              <div>
                {this.props.t(
                  'AffordabilityForm.are_there_any_expected_changes_in_your_personal_circumstances_that_would_result_in_you_being_unable_to_repay_the_loan_over_the_term_of_the_agreement'
                )}{' '}
                <ButtonInfoIcon onClick={this.openModal('modalExpectedChanges')} />
              </div>
            </MobxFormLabel>
            {enableYesNoAffordabilityQuestions() ? (
              <YesNoInput
                id="PersonalCircumstances.ChangesExpected"
                value={this.state.formData.PersonalCircumstances.ChangesExpected}
                onChange={this.handleFieldChange}
              />
            ) : (
              <SelectInput
                id="PersonalCircumstances.ChangesExpected"
                dataThook="PersonalCircumstances.ChangesExpected"
                options="YesOrNo"
                value={this.state.formData.PersonalCircumstances.ChangesExpected}
                onChange={this.handleFieldChange}
              />
            )}
          </MobxFieldGroup>
        )}

        {applicationFields.ChangesExpected &&
          this.state.formData.PersonalCircumstances &&
          this.state.formData.PersonalCircumstances.ChangesExpected &&
          this.state.formData.PersonalCircumstances.ChangesExpected.toString() === 'true' && (
            <>
              <MobxFieldGroup
                isInline
                error={errors.PersonalCircumstances && errors.PersonalCircumstances.DetailsOfExpectedChanges}
              >
                <MobxFormLabel>{this.props.t('AffordabilityForm.if_yes_what_are_the_expected_changes')}</MobxFormLabel>
                <TextInputWrappingInput
                  id="PersonalCircumstances.DetailsOfExpectedChanges"
                  dataThook="PersonalCircumstances.DetailsOfExpectedChanges"
                  value={this.state.formData.PersonalCircumstances.DetailsOfExpectedChanges}
                  onChange={(val) => this.handleFieldChange('PersonalCircumstances.DetailsOfExpectedChanges', val)}
                />
              </MobxFieldGroup>
            </>
          )}

        <FormFooter
          onSave={this.props.onSave && this.onSave}
          onCancel={this.props.onCancel}
          submittingState={this.props.submittingState}
          savingState={this.props.savingState}
        />

        <Modal isOpen={this.state.modalOpen === 'modalGross'} onClose={this.closeModal}>
          <ModalGross />
        </Modal>
        <Modal isOpen={this.state.modalOpen === 'modalReplacement'} onClose={this.closeModal}>
          <ModalReplacement />
        </Modal>
        <Modal isOpen={this.state.modalOpen === 'modalHasOtherHouseholdIncome'} onClose={this.closeModal}>
          <ModalOtherHouseholdIncome />
        </Modal>

        <Modal isOpen={this.state.modalOpen === 'modalExpectedChanges'} onClose={this.closeModal}>
          <ModalExpectedChanges />
        </Modal>

        <Modal isOpen={this.state.modalOpen === 'modalYourShare'} onClose={this.closeModal}>
          <ModalYourShare />
        </Modal>

        <Modal isOpen={this.state.modalOpen === 'modalOtherOutgoings'} onClose={this.closeModal}>
          <ModalOtherOutgoings funder={this.props.funderCode} />
        </Modal>
      </MobxForm>
    );
  }
}

AffordabilityForm.propTypes = {
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  validationRules: PropTypes.object.isRequired,
  applicationFields: PropTypes.object.isRequired,
  savingState: PropTypes.string.isRequired,
  submittingState: PropTypes.string,
  funderCode: PropTypes.string
};

export default withTranslation('Application')(inject(['appStore'])(AffordabilityForm));
