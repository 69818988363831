import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './toggleSwitch.scss';
import classnames from 'classnames';
import Icon from './Icon/Icon';

class ToggleSwitch extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    isActive: PropTypes.bool,
    disabled: PropTypes.bool,
    handleUpdate: PropTypes.func,
    showValueOnly: PropTypes.bool,
    dataTag: PropTypes.string,
    trackingPage: PropTypes.string,
    dataThook: PropTypes.string,
    positiveText: PropTypes.string,
    negativeText: PropTypes.string,
    defaultValue: PropTypes.bool,
    showToggleText: PropTypes.bool,
    smallBlueVariant: PropTypes.bool
  };

  static defaultProps = {
    showToggleText: true,
    smallBlueVariant: false
  };

  constructor(props) {
    super(props);
    this.state = {
      isFocused: false,
      hasHover: false
    };
  }

  toggleState = () => {
    const checked = !this.props.isActive;

    if (this.props.handleUpdate && !this.props.disabled) {
      this.props.trackingPage &&
        window.ga &&
        window.ga(
          'send',
          'event',
          this.props.trackingPage,
          `ToggleItem`,
          `${this.props.id}${checked ? 'Enabled' : 'Disabled'}`
        );

      this.props.handleUpdate(checked);
    }
  };

  onFocus = () => {
    this.setState({ isFocused: true });
  };

  onBlur = () => {
    this.setState({ isFocused: false });
  };

  onMouseEnter = () => {
    this.setState({ hasHover: true });
  };

  onMouseLeave = () => {
    this.setState({ hasHover: false });
  };

  render() {
    const { showToggleText, positiveText, negativeText, smallBlueVariant } = this.props;
    const positiveTextValue = showToggleText ? positiveText || this.props.t('ToggleSwitch.yes') : '';
    const negativeTextValue = showToggleText ? negativeText || this.props.t('ToggleSwitch.no') : '';

    let outerClasses = classnames(
      'toggleSwitch__outer',
      smallBlueVariant && 'toggleSwitch__smallBlueVariant',
      this.props.isActive && 'toggleSwitch__outerActive',
      !this.props.disabled && this.props.isActive && this.state.hasHover && 'toggleSwitch__active--hover',
      !this.props.disabled && !this.props.isActive && this.state.hasHover && 'toggleSwitch__base--hover',
      this.props.disabled && 'toggleSwitch__outerDisabled',
      this.state.isFocused && !this.props.disabled && 'toggleSwitch__focused'
    );

    let innerClasses = classnames(
      'toggleSwitch__spanBase',
      this.props.isActive && 'toggleSwitch__spanActive',
      this.props.disabled && 'toggleSwitch__spanDisabled'
    );

    if (this.props.showValueOnly) {
      return (
        <div className={this.props.isActive ? 'toggleSwitch__valueOnly--valid' : 'toggleSwitch__valueOnly--inValid'}>
          <Icon name={this.props.isActive ? 'validation-tick' : 'validation-cross'} />
        </div>
      );
    }

    return (
      <div
        className="toggleSwitch__wrapper"
        onClick={this.toggleState}
        data-name={this.props.dataTag}
        data-checked={this.props.isActive}
        data-th="ToggleSwitch"
      >
        <input
          id={this.props.id}
          className="toggleSwitch__input"
          type="checkbox"
          checked={this.props.isActive}
          defaultValue={this.props.defaultValue}
          readOnly={true}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          disabled={this.props.disabled}
          data-thook={`${this.props.dataThook}Toggle`}
        />
        <span className={outerClasses}>
          <span className={innerClasses} />
          {this.props.isActive && <div className="toggleSwitch__lettering--positive">{positiveTextValue}</div>}
          {!this.props.isActive && <div className="toggleSwitch__lettering--negative">{negativeTextValue}</div>}
        </span>
      </div>
    );
  }
}

export default withTranslation('Common')(ToggleSwitch);
