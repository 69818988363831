import platformApiUtils from '@ivendi/platform-api-utils';
import * as actionTypes from '../actionTypes';
import * as saveQuickQuoteModalActions from './saveQuickQuoteModalActions';
import uuid from 'uuid';
import { submitMultipleApplications } from '../../api/application';
import { push } from '../../routerHistory';
import { objectToSearchParams } from 'utils/objectToSearchParams';
import { trackVehicleFinanceApplicationStarted } from '../../tracking/avoTracking';

export function createQuickQuoteApplication(personalDetails) {
  const applicationId = uuid.v4();
  const customerId = uuid.v4();
  const vehicleId = uuid.v4();

  return (dispatch, getState) => {
    const state = getState();
    const dealershipId = state.dealership.Id;
    const vehicle = state.quickQuote.vehicle;
    const quote = state.quickQuote.quote.VehicleLoan ? state.quickQuote.quote.VehicleLoan : state.quickQuote.quote;
    let multiQuoteRefId;
    let applicationCount = 1;

    if (state.quickQuote.quote.VehicleLoan) {
      multiQuoteRefId = uuid.v4();
      if (state.quickQuote.quote.PersonalLoan) {
        applicationCount++;
      }
      if (state.quickQuote.quote.NegativeEquityLoan) {
        applicationCount++;
      }
    }

    dispatch({
      types: [
        actionTypes.CREATE_QUICK_QUOTE_APPLICATION,
        actionTypes.CREATE_QUICK_QUOTE_APPLICATION_SUCCESS,
        actionTypes.CREATE_QUICK_QUOTE_APPLICATION_ERROR
      ],
      callAPI: () =>
        platformApiUtils.post('v2/quick-quote/', {
          ApplicationId: applicationId,
          CopyApplicationFrom: null, //????
          // ApplicationRequestType: requestType,
          CustomerType: 'consumer',
          CustomerId: customerId, //???
          Cashback: quote.Cashback,
          RequestedDeposit: quote.RequestedDeposit,
          VehicleId: vehicleId,
          QuoteId: quote.QuoteId,
          CapId: vehicle.CapId || -1,
          GlassId: vehicle.GlassId || !vehicle.CapId ? -1 : undefined,
          MakeId: vehicle.MakeId || -1,
          ModelId: vehicle.ModelId || -1,
          CapMakeId: vehicle.CapMakeId || -1,
          CapModelId: vehicle.CapModelId || -1,
          Make: vehicle.Make,
          Model: vehicle.Model,
          Derivative: vehicle.Derivative,
          Class: vehicle.Class,
          Condition: vehicle.Condition,
          Vrm: vehicle.Vrm,
          Vin: vehicle.Vin,
          SanitizedVrm: vehicle.SanitizedVrm,
          RegistrationDate: vehicle.RegistrationDate,
          Mileage: vehicle.Mileage,
          CdnVehicleImageUrl: vehicle.CdnVehicleImageUrl,
          DealershipId: dealershipId,

          Title: personalDetails.Title,
          Firstname: personalDetails.FirstName,
          Middlenames: personalDetails.MiddleNames,
          Surname: personalDetails.LastName,
          Email: personalDetails.Email,
          Landline: personalDetails.HomeTelephone,
          Mobile: personalDetails.MobileTelephone,
          Gender: personalDetails.Gender,
          DateOfBirth: personalDetails.DateOfBirth,
          Nationality: personalDetails.Nationality,
          DualNationality: personalDetails.DualNationality,
          MaritalStatus: personalDetails.MaritalStatus,
          NoOfDependants: personalDetails.NoOfDependants,
          DrivingLicence: personalDetails.DrivingLicence,
          DrivingLicenceNumber: personalDetails.DrivingLicenceNumber,
          AssignToSelf: personalDetails.AssignToSelf,
          CountryOfBirth:
            personalDetails.CountryOfBirth &&
            (personalDetails.CountryOfBirth.BornUK === 'false' ? personalDetails.CountryOfBirth.BirthPlace : 'GB'),
          DirectorOrBoardMemberCountryOfOperation:
            personalDetails.DirectorOrBoardMember &&
            (personalDetails.DirectorOrBoardMember.Director === 'false'
              ? null
              : personalDetails.DirectorOrBoardMember.CountryOfOperation),
          DirectorOrBoardMemberCompanySector:
            personalDetails.DirectorOrBoardMember &&
            (personalDetails.DirectorOrBoardMember.Director === 'false'
              ? null
              : personalDetails.DirectorOrBoardMember.CompanySector),
          VatNumber: personalDetails.VatNumber,
          Tags: JSON.stringify({
            multiQuoteRefId,
            applicationCount
          })
        }),
      onSuccess: (state, response) => {
        // Combined quote
        if (state.quickQuote.quote.VehicleLoan) {
          let quoteIds = [];
          let quote = state.quickQuote.quote;

          if (quote.PersonalLoan) {
            quoteIds.push(quote.PersonalLoan.QuoteId);
          }
          if (quote.NegativeEquityLoan) {
            quoteIds.push(quote.NegativeEquityLoan.QuoteId);
          }

          // Here we will create potentially 2 applications
          // One for Personal Loan and one for Negative Equity Loan (whichever exist)
          // However, we will not create a Vehicle Loan Application, because on Quick Quote
          // that will happen after the personal details page is submitted
          submitMultipleApplications(
            response.CustomerId || customerId,
            response.VehicleId || vehicleId,
            quoteIds,
            'consumer',
            multiQuoteRefId,
            quote,
            dealershipId
          );
        }

        // When applying from quick quote we want to always track the application
        // as it will always be a vehicle loan one
        trackVehicleFinanceApplicationStarted({
          dealershipId,
          financeFunderCode: quote.FunderCode,
          financeFunderProductId: quote.FunderProductUID,
          financeProductType: quote.FinanceType,
          financeApplicationId: applicationId,
          quoteId: quote.QuoteId
        });

        if (response.CustomerId) {
          push(`/d/${dealershipId}/consumers/${response.CustomerId}/application/${applicationId}/addresshistory`);
        } else {
          push(`/d/${dealershipId}/consumers/${customerId}/application/${applicationId}/addresshistory`);
        }
      }
    });
  };
}

export function saveQuickQuoteApplication(personalDetails, requestType) {
  const applicationId = uuid.v4();
  const customerId = uuid.v4();
  const vehicleId = uuid.v4();

  return (dispatch, getState) => {
    const state = getState();
    const dealershipId = state.dealership.Id;
    const vehicle = state.quickQuote.vehicle;
    const quote = state.quickQuote.quote;
    const isDe = (state.dealership.CountryCode || '').toLowerCase() === 'de';

    dispatch({
      types: [
        actionTypes.SAVE_QUICK_QUOTE_APPLICATION,
        actionTypes.SAVE_QUICK_QUOTE_APPLICATION_SUCCESS,
        actionTypes.SAVE_QUICK_QUOTE_APPLICATION_ERROR
      ],
      callAPI: () =>
        platformApiUtils.post('v2/quick-quote/', {
          ApplicationId: applicationId,
          CopyApplicationFrom: null,
          ApplicationRequestType: requestType,
          CustomerType: 'consumer',
          CustomerId: customerId,
          VehicleId: isDe ? vehicle.VehicleId : vehicleId,
          QuoteId: quote.QuoteId,
          Cashback: quote.Cashback,
          RequestedDeposit: quote.RequestedDeposit,
          Make: vehicle.Make,
          Model: vehicle.Model,
          Derivative: vehicle.Derivative,
          CapId: vehicle.CapId || -1,
          GlassId: vehicle.GlassId || !vehicle.CapId ? -1 : undefined,
          MakeId: vehicle.MakeId || -1,
          ModelId: vehicle.ModelId || -1,
          CapMakeId: vehicle.CapMakeId || -1,
          CapModelId: vehicle.CapModelId || -1,
          Class: vehicle.Class,
          Condition: vehicle.Condition,
          Vrm: isDe ? null : vehicle.Vrm,
          Vin: vehicle.Vin,
          SanitizedVrm: vehicle.SanitizedVrm,
          RegistrationDate: vehicle.RegistrationDate,
          Mileage: vehicle.Mileage,
          CdnVehicleImageUrl: vehicle.CdnVehicleImageUrl,
          DealershipId: dealershipId,

          Title: personalDetails.Title,
          Firstname: personalDetails.FirstName,
          Middlenames: personalDetails.MiddleNames,
          Surname: personalDetails.LastName,
          Email: personalDetails.Email,
          Landline: personalDetails.Landline,
          Mobile: personalDetails.Mobile,
          AssignToSelf: personalDetails.AssignToSelf
        }),
      onSuccess: () => {
        dispatch(saveQuickQuoteModalActions.closeSaveQuickQuoteModal());
        push({
          pathname: `/d/${dealershipId}/consumers/${customerId}`,
          search: objectToSearchParams({
            vehicleId: isDe ? vehicle.VehicleId : vehicleId,
            selectedFinanceTab: 'SavedQuotes'
          }).toString()
        });
      }
    });
  };
}

export function saveQuickQuoteToExistingCustomer(dealershipConsumerId) {
  return (dispatch, getState) => {
    const state = getState();
    const dealershipId = state.dealership.Id;
    const quote = state.quickQuote.quote;
    const vehicle = state.quickQuote.vehicle;

    dispatch({
      types: [
        actionTypes.CREATE_QUICK_QUOTE_APPLICATION_EXITING_CUSTOMER,
        actionTypes.CREATE_QUICK_QUOTE_APPLICATION_EXITING_CUSTOMER_SUCCESS,
        actionTypes.CREATE_QUICK_QUOTE_APPLICATION_EXITING_CUSTOMER_ERROR
      ],
      callAPI: () =>
        platformApiUtils.post('v1/customer/' + dealershipConsumerId + '/quote', {
          QuoteId: quote.QuoteId,
          Vin: vehicle.Vin,
          Vrm: vehicle.Vrm,
          Vehicle: {
            Make: vehicle.Make,
            Model: vehicle.Model,
            Derivative: vehicle.Derivative,
            IvendiId: vehicle.VehicleId || null,
            CdnImageUrl: vehicle.CdnVehicleImageUrl,
            Vin: vehicle.Vin,
            Vrm: vehicle.Vrm
          }
        }),
      onSuccess: () => {
        dispatch(saveQuickQuoteModalActions.closeSaveQuickQuoteModal());

        push({
          pathname: `/d/${dealershipId}/consumers/${dealershipConsumerId}`,
          query: {
            selectLatestVehicle: true,
            selectedFinanceTab: 'SavedQuotes'
          }
        });
      }
    });
  };
}

export function changeQuickQuoteVehicle(vehicle) {
  return {
    type: actionTypes.CHANGE_QUICK_QUOTE_VEHICLE,
    payload: {
      vehicle
    }
  };
}

export function setQuickQuoteEditingVehicle() {
  return {
    type: actionTypes.SET_QUICK_QUOTE_EDITING_VEHICLE
  };
}

export function resetQuickQuoteEditingVehicle() {
  return {
    type: actionTypes.RESET_QUICK_QUOTE_EDITING_VEHICLE
  };
}

export function changeQuote(chosenQuote) {
  return {
    type: actionTypes.CHANGE_QUICK_QUOTE_QUOTE,
    payload: {
      chosenQuote
    }
  };
}

export function endQuoting() {
  return {
    type: actionTypes.END_QUOTING
  };
}
