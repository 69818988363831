import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { observer } from 'mobx-react';
import YearsMonthsField from '../../Common/Form/YearsMonthsField';
import HiddenField from '../../Common/HiddenField';
import ToggleButtonWithIcon from '../components/ToggleButtonWithIcon';
import MobxForm from '../../MobxForm/MobxForm';
import MobxFieldGroup from '../../MobxForm/MobxFieldGroup';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';
import TextInputWrappingInput from '../../Common/Form/TextInputWrappingInput';
import Validator from '../../../validators/Validator';
import InformationWarning from '../../Common/InformationWarning';
import FormFooter from '../../Common/Form/FormFooter';
import './bankDetailsForm.scss';

class BankDetailsForm extends React.Component {
  UNSAFE_componentWillMount() {
    this.setInitialFormData(this.props.defaultValues);
    this.setupValidation(this.props.validationRules);
    this.validator.validate(this.props.defaultValues);
  }

  setInitialFormData = (data) => {
    const isBankDetailsHidden = !_.isEmpty(this.props.defaultValues.AccountName);
    this.setState({
      formData: data,
      isBankDetailsHidden,
      isButtonVisible: isBankDetailsHidden
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.validationRules !== prevProps.validationRules) {
      this.setupValidation(this.props.validationRules);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.savingState !== 'loading' && nextProps.submittingState !== 'loading' && nextProps.bankLookupError) {
      const validationErrors = {
        SortCode: {
          message: this.props.t('BankDetailsForm.sort_code_does_not_match_account_number')
        },
        AccountNumber: {
          message: this.props.t('BankDetailsForm.account_number_does_not_match_sort_code')
        }
      };
      this.setState({
        validationErrors
      });
    }
  }

  setupValidation(validationRules) {
    this.validator = new Validator();
    this.validator.setRules(validationRules);
  }

  handleHiddenValuesToggle = () => {
    this.setState({
      isBankDetailsHidden: !this.state.isBankDetailsHidden
    });
  };
  handleFieldChangeYM = (id, value) => {
    let years = this.state.formData.TimeAtBank.Years;
    let months = this.state.formData.TimeAtBank.Months;

    if (value > 11) {
      years = Math.floor(value / 12);
      months = value % 12;
    } else {
      years = 0;
      months = value;
    }

    this.setState(
      (prevState) => {
        const oldItem = prevState.formData.TimeAtBank;
        Object.assign(oldItem, {
          [id]: value,
          Years: years,
          Months: months
        });
        return { ...prevState };
      },
      () => this.validator.validate(this.state.formData)
    );
    this.calculateTotalMonths();
  };
  calculateTotalMonths = () => {
    this.setState((prevState) => {
      let updatedtotalMonths = 0;
      updatedtotalMonths += prevState.formData.TimeAtBank.TotalMonths;
      return { ...prevState, formData: { ...prevState.formData, totalMonths: updatedtotalMonths } };
    });
  };
  handleFieldChange = (id, value) => {
    this.setState((prevState) => {
      let newState = prevState.formData;
      let validationErrors = prevState.validationErrors;
      newState = { ...newState, [id]: value };
      this.validator.validate(newState);

      if (id === 'SortCode' || id === 'AccountNumber') {
        validationErrors = {};
      }

      return { ...prevState, formData: { ...newState }, validationErrors };
    });
  };
  onSave = () => {
    this.validator.validate(this.state.formData);
    this.props.onSave(this.state.formData);
  };
  submitForm = () => {
    this.setState({
      isFormSubmitted: true
    });

    if (!this.validator.errorCount) {
      this.props.onSubmit(this.state.formData);
    }
  };

  render() {
    const formData = this.state.formData;
    const errors = this.validator.getErrors();
    let shouldShowInformationWarning =
      this.props.funderCode === 'CRE' && !errors.AccountName && !errors.SortCode && !errors.AccountNumber;
    return (
      <MobxForm onSubmit={this.submitForm} dataTh="BankDetailsForm">
        <MobxFieldGroup isInline error={errors.AccountName}>
          <MobxFormLabel>
            <div>{this.props.t('BankDetailsForm.account_name')}</div>
          </MobxFormLabel>
          <HiddenField value={formData.AccountName} dataThook={'AccountName'} isHidden={this.state.isBankDetailsHidden}>
            <TextInputWrappingInput
              id="AccountName"
              value={formData.AccountName}
              onChange={(val) => this.handleFieldChange('AccountName', val)}
            />
          </HiddenField>
        </MobxFieldGroup>
        <MobxFieldGroup
          isInline
          error={errors.SortCode || (this.state.validationErrors && this.state.validationErrors.SortCode)}
        >
          <MobxFormLabel>
            <div>{this.props.t('BankDetailsForm.sort_code')}</div>
          </MobxFormLabel>
          <HiddenField value={formData.SortCode} dataThook={'SortCode'} isHidden={this.state.isBankDetailsHidden}>
            <TextInputWrappingInput
              id="SortCode"
              value={formData.SortCode}
              onChange={(val) => this.handleFieldChange('SortCode', val)}
            />
          </HiddenField>
        </MobxFieldGroup>
        <MobxFieldGroup
          isInline
          error={errors.AccountNumber || (this.state.validationErrors && this.state.validationErrors.AccountNumber)}
        >
          <MobxFormLabel>
            <div>{this.props.t('BankDetailsForm.account_number')}</div>
          </MobxFormLabel>
          <HiddenField
            value={formData.AccountNumber}
            isHidden={this.state.isBankDetailsHidden}
            dataThook={'AccountNumber'}
          >
            <TextInputWrappingInput
              id="AccountNumber"
              value={formData.AccountNumber}
              onChange={(val) => this.handleFieldChange('AccountNumber', val)}
            />
          </HiddenField>
        </MobxFieldGroup>

        <MobxFieldGroup isInline error={errors.TimeAtBank && errors.TimeAtBank.TotalMonths}>
          <MobxFormLabel>
            <div>{this.props.t('BankDetailsForm.time_at_bank')}</div>
          </MobxFormLabel>
          <YearsMonthsField
            id="TimeAtBank"
            name="TimeAtBank"
            value={formData.TimeAtBank.TotalMonths}
            onChange={(val) => this.handleFieldChangeYM('TotalMonths', val)}
          />
        </MobxFieldGroup>

        {this.state.isButtonVisible && (
          <div className="bankDetailsForm__detailsToggleOuter">
            <div className="bankDetailsForm__detailsToggle">
              <ToggleButtonWithIcon
                onClick={this.handleHiddenValuesToggle}
                hideDetails={this.state.isBankDetailsHidden}
                icon="eye"
                text={this.props.t('BankDetailsForm.bank_details')}
              />
            </div>
          </div>
        )}

        {shouldShowInformationWarning && (
          <div className="bankDetailsForm__informationWarning">
            <InformationWarning>
              <div>
                {this.props.t('BankDetailsForm.in_order_for_the_lender_to_set_up_the_direct_debit_please_confirm_that')}
              </div>
              <ul className="bankDetailsForm__informationList">
                <li>{this.props.t('BankDetailsForm.this_account_belongs_to_the_customer')}</li>
                <li>{this.props.t('BankDetailsForm.the_account_is_in_the_customers_name')}</li>
                <li>
                  {this.props.t(
                    'BankDetailsForm.the_customer_is_the_only_required_signatory_for_setting_up_direct_debits'
                  )}
                </li>
              </ul>
            </InformationWarning>
          </div>
        )}
        <FormFooter
          onSave={this.props.onSave && this.onSave}
          onCancel={this.props.onCancel}
          submittingState={this.props.submittingState}
          savingState={this.props.savingState}
        />
      </MobxForm>
    );
  }
}

BankDetailsForm.propTypes = {
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  validationRules: PropTypes.object.isRequired,
  savingState: PropTypes.string,
  submittingState: PropTypes.string,
  funderCode: PropTypes.string
};

export default withTranslation('Application')(observer(BankDetailsForm));
