import PropTypes from 'prop-types';
import ConsumerVehicleInfo from '../Customer/components/ConsumerVehicleInfo';
import AlgoliaVehicle from '~/mobx-models/AlgoliaVehicle';
import './consumerVehicleInfoPanel.scss';

export type ConsumerVehicleCondition = 'New' | 'Used' | 'new' | 'used';
export type ConsumerVehicleType = {
  Make: string;
  Vrm: string;
  VehicleVrm?: string;
  Vin?: string;
  Model: string;
  Derivative: string;
  Condition: ConsumerVehicleCondition;
  Class: string;
  RawVehicle: AlgoliaVehicle;
  VehicleId?: string;
};
export type ConsumerVehicleQuoteType = {
  VehicleVrm: string;
  VehicleMake: string;
  VehicleModel: string;
  VehicleDerivative: string;
  Condition: ConsumerVehicleCondition;
};
type ConsumerVehicleInfoPanelProps = {
  vehicle: ConsumerVehicleType;
  quote?: ConsumerVehicleQuoteType;
  dealershipId?: string;
  canEditVehicle: boolean;
  setQuickQuoteEditingVehicle: () => void;
};

const ConsumerVehicleInfoPanel = (props: ConsumerVehicleInfoPanelProps) => {
  return (
    <div className="consumerVehicleInfoPanel">
      <ConsumerVehicleInfo {...props} />
    </div>
  );
};

ConsumerVehicleInfoPanel.propTypes = {
  vehicle: PropTypes.object
};

export default ConsumerVehicleInfoPanel;
