import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { objectToSearchParams } from 'utils/objectToSearchParams';
import './sidebarItem.scss';

const SidebarItem = ({ to, onClick, image, icon, children, sidebarIsHovered, dataThook, newTab }) => {
  const sidebarTextClassNames = classnames('sidebarItem__textHide', sidebarIsHovered && 'sidebarItem__textShow');

  const content = (
    <>
      {icon ? (
        <div className="sidebarItem__icon sidebarItem__icon-wrapper">{icon}</div>
      ) : (
        <div className={`sidebarItem__icon sidebarItem__icon--${image}`} />
      )}
      <div className={`sidebarItem__text ${sidebarTextClassNames}`}>{children}</div>
    </>
  );

  const button = () => {
    if (to && newTab) {
      return (
        <a className="sidebarItem__link" href={to} target="_blank" rel="noopener noreferrer" onClick={onClick}>
          {content}
        </a>
      );
    } else if (to) {
      return (
        <Link
          className="sidebarItem__link"
          to={
            typeof to === 'string'
              ? to
              : {
                  pathname: to.pathname,
                  search: to.search || objectToSearchParams(to.query).toString()
                }
          }
          onClick={onClick}
        >
          {content}
        </Link>
      );
    }

    return (
      <div className="sidebarItem__link" onClick={onClick}>
        {content}
      </div>
    );
  };

  return (
    <li className="sidebarItem" data-thook={dataThook} data-th={`SidebarItem-${dataThook}`}>
      {button()}
    </li>
  );
};

SidebarItem.propTypes = {
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onClick: PropTypes.func,
  image: PropTypes.string,
  children: PropTypes.node,
  dataThook: PropTypes.string,
  newTab: PropTypes.bool,
  icon: PropTypes.node
};

export default SidebarItem;
