import { useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '../../../hooks/useNavigate';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import Icon from '../../Common/Icon/Icon';
import TextQuickQuoteBar from './../TextQuickQuoteBar';
import { isAlphanumeric } from '../../../core/helpers';
import * as quickQuoteActions from '../../../redux/quickQuote/quickQuoteActions.js';
import closeButton from '../../../styles/icons/close-red-square.svg';
import { isMotorhomeOrTouringCaravan } from '~/types/vehicle';
import { useVehicleLookup } from '~/components/VrmLookup/hooks';
import { inject } from 'mobx-react';
import { formatVehicleObject } from '~/components/QuickQuote/components/utils';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

const QuickQuoteSearchBar = ({
  isOpen,
  vehicleClass,
  onStateChange,
  dealershipId,
  vehicleCondition,
  changeQuickQuoteVehicle,
  appStore
}) => {
  const { t } = useTranslation('Header');
  const navigate = useNavigate();
  const location = useLocation();

  const [vrm, setVrm] = useState('');

  const { lookupVehicle } = useVehicleLookup({
    checkAlgolia: appStore.uiState.hasStockEnabled,
    checkVrmLookup: appStore.uiState.isUk,
    dealershipId
  });

  const buttonClassNames = classnames('quickQuoteBar__mobileButton', isOpen && 'quickQuoteBar__mobileButton--active');
  const showVehicleInfoSearch = isMotorhomeOrTouringCaravan(vehicleClass);
  const vrmContainerClasses = classnames(
    showVehicleInfoSearch ? 'quickQuoteBar__enterVrmContainer__motorhome' : 'quickQuoteBar__enterVrmContainer',
    isOpen && 'quickQuoteBar__enterVrmContainer--open'
  );
  const isFindAVehiclePage = useFeatureIsOn('rtl-findavehicle-page');

  const handleButtonClick = () => {
    onStateChange(!isOpen);
  };

  const handleVrmChange = (evt) => {
    const { value: vrm } = evt.target;

    if (isAlphanumeric(vrm)) {
      setVrm(vrm);
    }
  };

  const handleNewVehicleQuickQuote = () => {
    onStateChange(!isOpen);
    navigate({
      pathname: `/d/${dealershipId}/quickquote`,
      query: {
        vehicleType: 'new'
      }
    });
  };

  const handleMultipleDerivatives = (vehicle) => {
    changeQuickQuoteVehicle(formatVehicleObject(vehicle));
    navigate({
      pathname: `/d/${dealershipId}/quickquote`,
      query: {
        vrm: vrm,
        multipleDerivatives: encodeURIComponent('true')
      }
    });
  };

  const handleQuickQuoteClick = async (e) => {
    e.preventDefault();

    try {
      const resultsPathname = `/d/${dealershipId}/quickquote/results`;

      const vehicle = await lookupVehicle(vrm);
      let formattedVehicle;

      if (vehicle.CapData) {
        const capVehicle = vehicle.CapData[0];
        if (vehicle.CapData.length > 1) {
          handleMultipleDerivatives(capVehicle);
          return;
        } else {
          capVehicle.Vin = vehicle.Vin;
          capVehicle.Condition = vehicleCondition;
          capVehicle.Vrm = vrm;
          formattedVehicle = formatVehicleObject(capVehicle);
        }
      } else {
        formattedVehicle = formatVehicleObject(vehicle);
      }
      if (location.pathname === resultsPathname) {
        appStore.quotingStore.setShouldResetQuotingState(true);
      }

      changeQuickQuoteVehicle(formattedVehicle);

      if (isFindAVehiclePage && (vehicle.AccuracyScore === 2 || vehicle.AccuracyScore === 3)) {
        navigate({
          pathname: `/d/${dealershipId}/stock/${vehicle.Id}/fix-issues`
        });
        // If vehicle was found and rtl-findavehicle-page feature flag is true, go to the quote details form
        // If there is no CapData, the vehicle is from stock and will go to the quote details form
      } else if (isFindAVehiclePage || !vehicle.CapData) {
        navigate({
          pathname: `/d/${dealershipId}/quickquote/results`,
          query: {
            section: 'costs_view'
          }
        });
      } else {
        // If vehicle was found but the feature flag is false, go to the quick quote form.
        navigate({
          pathname: `/d/${dealershipId}/quickquote`,
          query: {
            vrm: vrm,
            vehicleType: 'used'
          }
        });
      }
    } catch (e) {
      navigate({
        pathname: `/d/${dealershipId}/quickquote`,
        query: {
          vrm: vrm,
          vehicleType: 'used'
        }
      });
    } finally {
      setVrm('');
      onStateChange(!isOpen);
    }
  };

  return (
    <>
      <div className="quickQuoteBar__mobileButtonOuter">
        <button className={buttonClassNames} onClick={handleButtonClick}>
          <div className="quickQuoteBar__mobileButtonImage">
            <Icon name="quick-quote" />
          </div>
          <div className="quickQuoteBar__mobileButtonText">{t('QuickQuoteBar.quote')}</div>
        </button>
      </div>
      <div className={vrmContainerClasses}>
        <div className="quickQuoteBar__mobileHeader">
          <div className="quickQuoteBar__mobileHeaderText">
            {showVehicleInfoSearch ? t('QuickQuoteBar.enter_vehicle_info') : t('QuickQuoteBar.enter_a_vrm')}
          </div>
          <button className="quickQuoteBar__mobileHeaderCrossContainer" onClick={handleButtonClick}>
            <img src={closeButton} className="quickQuoteBar__mobileHeaderCloseButton" alt="close" />
          </button>
        </div>
        {showVehicleInfoSearch ? (
          <TextQuickQuoteBar
            onStateChange={onStateChange}
            isOpen={isOpen}
            dealershipId={dealershipId}
            vehicleCondition={vehicleCondition}
            vehicleClass={vehicleClass}
          />
        ) : (
          <>
            <div className="quickQuoteBar__mobileHeaderTopSection">
              <form className="quickQuoteBar__mobileHeaderForm" onSubmit={handleQuickQuoteClick}>
                <div className="quickQuoteBar__mobileHeaderBlueBit" />
                <div className="quickQuoteBar__vrmInputContainer">
                  <input
                    className="quickQuoteBar__vrmInput"
                    value={vrm}
                    onChange={handleVrmChange}
                    maxLength={9}
                    data-th="QuickQuoteBarInput"
                  />
                </div>
                <div className="quickQuoteBar__buttonOuter">
                  <button type="submit" className="quickQuoteBar__button">
                    {t('QuickQuoteBar.quick_quote')}
                  </button>
                </div>
              </form>
            </div>
            <div className="quickQuoteBar__mobileHeaderBottomSection">
              <div
                className="quickQuoteBar__mobileHeaderBottomSectionText"
                onClick={handleNewVehicleQuickQuote}
                key="bottomText"
              >
                <div className="quickQuoteBar__mobileHeaderBottomSectionIcon">
                  <Icon name="quick-quote" />
                </div>{' '}
                {t('QuickQuoteBar.quote_new_vehicle')}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    changeQuickQuoteVehicle: (vehicle) => {
      dispatch(quickQuoteActions.changeQuickQuoteVehicle(vehicle));
    }
  };
}

export default inject('appStore')(connect(null, mapDispatchToProps)(QuickQuoteSearchBar));
