import { observable } from 'mobx';
import moment from 'moment';

class AlgoliaVehicle {
  @observable AccuracyScore;
  @observable CapId;
  @observable Class;
  @observable Condition;
  @observable Doors;
  @observable SDoors;
  @observable Id;
  @observable Colour;
  @observable Derivative;
  @observable Fuel;
  @observable Make;
  @observable Mileage;
  @observable Model;
  @observable Price;
  @observable RegistrationDate;
  @observable Registered;
  @observable Transmission;
  @observable Vrm;
  @observable VrmYear;
  @observable FafAgeRuleOk;
  @observable FafPriceRuleOk;
  @observable FafMileageRuleOk;
  @observable Taxonomies;
  @observable PreferredTaxonomy;
  @observable Vin;
  @observable Seats;
  @observable DealerPlatformUpload;
  @observable ImagesMetadata;
  @observable Bhp;
  @observable PwrKW;
  @observable BadgeEngineSize;
  @observable CC;
  @observable Description;
  @observable DealerReference;
  @observable ValidationErrors;
  @observable ImgPth;
  @observable Imgs;
  @observable InStockSince;
  @observable objectID;

  updateFromJSON(json = {}) {
    this.AccuracyScore = json.accuracyScore;
    this.CapId = (json?.preferredTaxonomy?.provider === 'cap' && json.preferredTaxonomy?.secondaryId) || null;
    this.SClass = json.sClass;
    this.DealerId = json.dealerId;
    this.Id = json.id;
    this.Doors = json.doors;
    this.SDoors = json.sDoors;
    this.Class = json?.preferredTaxonomy?.class || json.class;
    this.Colour = json.colour;
    this.Condition = json.condition ? json.condition : 'used';
    this.SDerivative = json.sDerivative;
    this.Derivative = json.derivative;
    this.Fuel = json.fuel;
    this.SFuel = json.sFuel;
    this.SMake = json.sMake;
    this.Make = json.make;
    this.Mileage = json.mileage;
    this.Model = json.model;
    this.SModel = json.sModel;
    this.Price = json.price;
    this.RegistrationDate =
      json.regDate !== undefined && moment(json.regDate).isValid() ? moment(json.regDate).format('DD/MM/YYYY') : '';
    this.STransmission = json.sTrans;
    this.Transmission = json.trans;
    this.Taxonomies = json.taxonomies;
    this.PreferredTaxonomy = json.preferredTaxonomy;
    this.Vin = json.vin;
    this.Vrm = json.vrm;
    this.VrmYear = json.vrmYear;
    this.FafAgeRuleOk = typeof json.fafAgeRuleOk !== 'undefined' ? json.fafAgeRuleOk : '1';
    this.FafPriceRuleOk = typeof json.fafPriceRuleOk !== 'undefined' ? json.fafPriceRuleOk : '1';
    this.FafMileageRuleOk = typeof json.fafMileageRuleOk !== 'undefined' ? json.fafMileageRuleOk : '1';
    this.SBodyStyle = json.sBodyStyle;
    this.BodyStyle = json.bodyStyle;
    this.SuppliedBodyStyle = json.suppliedBodyStyle;
    this.SuppliedDerivative = json.suppliedDerivative;
    this.SuppliedDoors = json.suppliedDoors;
    this.SuppliedFuelType = json.suppliedFuelType;
    this.SuppliedMake = json.suppliedMake;
    this.SuppliedModel = json.suppliedModel;
    this.DealerPlatformUpload = json.dealerPlatformUpload;
    this.ImagesMetadata = json.imagesMetadata;
    this.Bhp = json.bhp;
    this.BadgeEngineSize = json.badgeEngineSize;
    this.PwrKW = json.pwrKW;
    this.CC = json.cc;
    this.Description = json.description;
    this.NoOfSeats = json?.noOfSeats;
    this.DealerReference = json.dealerReference;
    this.ValidationErrorCodes = json.validationErrorCodes;
    this.ImgPth = json.imgPth;
    this.Imgs = json.imgs;
    this.VideosMetadata = json.videosMetadata;
    this.MediaCompanyName = json.mediaCompanyName;
    this.InStockSince = json.inStockSince;
    this.objectID = json.objectID;
  }
}

export default AlgoliaVehicle;
