import moment from 'moment';

export type Vehicle = {
  CapId?: number;
  CdnVehicleImageUrl?: string;
  Class?: string;
  Condition?: string;
  Derivative?: string;
  DerivativeId?: number | string;
  Id?: string;
  Make?: string;
  MakeId?: number | string;
  Mileage?: number;
  Model?: string;
  ModelId?: number | string;
  Price?: number;
  RegistrationDate?: string;
  Registered?: string;
  SClass?: string;
  SDerivative?: string;
  SMake?: string;
  SModel?: string;
  VehicleClass?: string;
  Vrm?: string;
  Vin?: string;
  VehicleId?: string;
  DataSource?: string;
  SecondaryTaxonomyId?: number;
};

export type VisVehicle = {
  Class: string;
  Condition: string;
  Derivative: string;
  DerivativeId: string;
  GlassId: number;
  Make: string;
  MakeId: string;
  Mileage: string;
  Model: string;
  ModelId: string;
  RegistrationDate: string;
  Vin: string;
  Vrm: string;
};

export const getVehicleCapId = (vehicle: Vehicle) => {
  // Uses data coming from the CAP dataset
  const mainCapId = vehicle.CapId;
  // Uses data coming from the Algolia taxonomy search
  const taxonomyCapId = vehicle?.DataSource?.toLowerCase() === 'cap' ? vehicle.SecondaryTaxonomyId : null;
  return mainCapId || taxonomyCapId || null;
};

export const formatVehicleObject = (vehicle: Vehicle, condition?: string, vrm?: string) => {
  return {
    CapId: getVehicleCapId(vehicle),
    Class: vehicle.Class || vehicle.SClass || (vehicle.VehicleClass ? vehicle.VehicleClass?.toLowerCase() : ''),
    Condition: vehicle.Condition ? vehicle.Condition : condition || 'used',
    Derivative: vehicle.Derivative || vehicle.SDerivative,
    Make: vehicle.Make || vehicle.SMake,
    Mileage: (vehicle.Mileage !== undefined && vehicle.Mileage.toString()) || '',
    Model: vehicle.Model || vehicle.SModel,
    Price: vehicle.Price,
    RegistrationDate:
      vehicle.RegistrationDate || (vehicle.Registered && moment(vehicle.Registered).format('DD/MM/YYYY')) || '',
    Vrm: vrm || vehicle.Vrm,
    VehicleId: vehicle.Id,
    DerivativeId: getVehicleCapId(vehicle), // We're not sure if this is used, but keeping it for backwards compatibility
    MakeId: vehicle.MakeId,
    ModelId: vehicle.ModelId,
    CdnVehicleImageUrl: vehicle.CdnVehicleImageUrl,
    Vin: vehicle.Vin,
    RawVehicle: vehicle
  };
};
