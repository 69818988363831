import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import TextInput from '../../Common/Form/TextInput';
import Button from '../Button/Button';
import FieldError from '../FieldError';
import PostcodeLookupModel from './PostcodeLookupModel';
import classnames from 'classnames';
import LoadingSelect from '../Loading/LoadingSelect';
import MobxFieldGroup from '../../MobxForm/MobxFieldGroup';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';

import './postcodeLookup.scss';

@observer
class PostcodeLookup extends React.Component {
  constructor(props) {
    super(props);
    this.postcodeLookup = new PostcodeLookupModel()
      .setPostcode(this.props.defaultValue)
      .setCountryCode(this.props.appStore.uiState.countryCode)
      .setOnSuccess(props.onSelection);
  }

  handleKeyPress = (e) => {
    //Prevent form submission on enter, search instead.
    if (e.key === 'Enter' && this.postcodeLookup.postcode) {
      e.preventDefault();
      e.stopPropagation();
      this.handleLookupPostcode();
    }
  };
  handleLookupPostcode = () => {
    this.postcodeLookup.lookup(this.props.dealershipId, this.props.quoteId);
  };
  handleAddressSelect = (e) => {
    this.postcodeLookup.lookupAddress(e.currentTarget.value, this.props.dealershipId, this.props.quoteId);
  };
  handleChange = (name, value) => {
    this.postcodeLookup.setPostcode(value);
  };

  render() {
    if (this.props.appStore.uiState.isDe) return null;

    const inputClasses = classnames('postcodeLookup__input', this.props.inline && 'postcodeLookup__input--inline');
    const innerClasses = classnames('postcodeLookup__inner', this.props.inline && 'postcodeLookup__inner--inline');
    const showErrorMessage =
      this.postcodeLookup.lookupPostcodeRequest.hasError || this.postcodeLookup.lookupAddressIdRequest.hasError;
    return (
      <div className="postcodeLookup" onKeyPress={this.handleKeyPress}>
        <MobxFieldGroup isInline={this.props.inline} fieldShowsErrors={false}>
          <MobxFormLabel>
            <div>{this.props.t('PostcodeLookup.postcode_lookup')}</div>
          </MobxFormLabel>

          <div className={innerClasses}>
            <div className="postcodeLookup__inputAndButton">
              <div className={inputClasses}>
                <TextInput
                  id="PostcodeLookup"
                  value={this.postcodeLookup.postcode}
                  onChange={this.handleChange}
                  dataThook="PostcodeLookup"
                />
              </div>
              <div className="postcodeLookup__button">
                <Button
                  type="button"
                  isLoading={this.postcodeLookup.lookupPostcodeRequest.isLoading}
                  hasError={this.postcodeLookup.lookupPostcodeRequest.hasError}
                  onClick={this.handleLookupPostcode}
                  dataThook="PostcodeLookup.Button"
                >
                  {this.props.t('PostcodeLookup.find')}
                </Button>
              </div>
            </div>

            {this.postcodeLookup.addresses.length > 0 && (
              <div className="postcodeLookup__selectContainer">
                <p className="postcodeLookup__selectLabel">{this.props.t('PostcodeLookup.select_your_address')}</p>
                <LoadingSelect
                  id="PostcodeLookup"
                  dataThook="PostcodeLookup"
                  request={this.postcodeLookup.lookupAddressIdRequest}
                  onChange={this.handleAddressSelect}
                >
                  <option key="optionDefault">- - -</option>

                  {this.postcodeLookup.addresses.map((item, index) => (
                    <option value={item.Id} key={`option${index}`}>
                      {/*replace() is temporary and needs to be cleaned up from backend*/}
                      {item.Suggestion.replace('Type: Address, ', '').replace('Type: Postcode, ', '')}
                    </option>
                  ))}
                </LoadingSelect>
              </div>
            )}
          </div>

          <div className="postcodeLookup__error">
            {showErrorMessage && (
              <FieldError dataThook="PostcodeLookup.Error">
                {this.props.t('PostcodeLookup.unable_to_locate_postcode')}
              </FieldError>
            )}
          </div>
        </MobxFieldGroup>
      </div>
    );
  }
}

PostcodeLookup.defaultProps = {
  defaultValue: ''
};
PostcodeLookup.propTypes = {
  onSelection: PropTypes.func.isRequired,
  inline: PropTypes.bool,
  defaultValue: PropTypes.string,
  defaultCountry: PropTypes.string,
  dealershipId: PropTypes.string,
  quoteId: PropTypes.string
};
export default withTranslation('Common')(inject(['appStore'])(PostcodeLookup));
