import { inject } from 'mobx-react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import CommissionValue from 'components/Common/CommissionValue';
import DateFormat from 'components/Common/DateFormat';
import SelfServicedApplicationTag from 'components/Common/SelfServicedApplicationTag';
import ErroredQuote from 'components/QuotesList/components/ErroredQuote';
import CombinedQuoteMessage from 'components/Common/CombinedQuoteMessage';
import QuoteButton from 'components/QuoteCard/components/QuoteButton';
import Checkbox from 'components/Common/Form/Checkbox';
import FunderLogo from 'components/Common/FunderLogo';
import FieldToggle from 'components/Common/Form/FieldToggle';
import ApplicationStatus, { StatusType } from '~/components/QuoteCard/components/ApplicationStatus/ApplicationStatus';
import DetailsTable from './components/DetailsTable/DetailsTable';
import MultiLoansContainer from './components/MultiLoans/MultiLoansContainer';
import { getAllLoansDisplaySchema, getQuoteDisplaySchema } from './utils/getQuoteDisplaySchema';
import { HeaderFigures, getHeaderFigures } from './components/HeaderFigures/HeaderFigures';
import { useGetQuoteChecks } from './hooks';
import { FunderType, LoanType, QuoteCardVariantType } from './types';
import { getVehicleLoanProductId } from '../PushDeal/utils/quoteUtils';
import PublicWarningMessage from './PublicWarning/PublicWarningMessage';
import { Tag } from '../Common/Tags';
import QuoteDisclaimer from './components/QuoteDisclaimer';
import './quoteCard.scss';

const QuoteCard = ({
  variant,
  quote,
  application,
  appStore,
  financeCheck,
  funders,
  vehicle,
  showFullQuote,
  actions,
  trackingPage = 'QuotesListView',
  schemesVisible = false,
  isQuickQuote,
  vehicleClass,
  isCustomerQuote,
  chosenQuoteId,
  proceedingState,
  newCarOffer,
  applicantName,
  lenderStatus,
  financeApplications,
  hasCheckedEligibility,
  dealershipId,
  consumerId,
  vehicleId,
  toggleMultiScheduleComponents
}: QuoteCardProps) => {
  const { t } = useTranslation('QuoteCard');
  const [isExpanded, setIsExpanded] = useState(false);
  useEffect(() => {
    showFullQuote !== undefined && setIsExpanded(showFullQuote);
  }, [showFullQuote]);
  const { uiState } = appStore;
  const { canCreateApplications, isBdk, canViewConsumerApplications, canGenerateQuotes } = uiState;
  const {
    isCombinedQuote,
    isFinanceQuote,
    isFinanceApplication,
    isSavedQuote,
    isSendDeal,
    isCarFinanceCheck,
    isRiskBasedPricingQuote,
    isRequote,
    hasCompanionQuotes,
    supportsPreApproval
  } = useGetQuoteChecks({ quote, variant });

  const isPreApprovedFinanceApplication =
    isFinanceApplication && application?.Agreements?.[0]?.PreApprovalData?.Success === true;

  const canShowPreApprovalAvailable =
    supportsPreApproval &&
    !isRequote &&
    !isPreApprovedFinanceApplication &&
    application?.LenderStatus === 'Not Submitted';

  const canShowApplicationStatus =
    (isFinanceApplication || isCarFinanceCheck || (isFinanceQuote && hasCheckedEligibility)) &&
    !canShowPreApprovalAvailable;

  const canShowIndicativeAprTag = !isRequote && quote?.CreditTier && quote.CreditTier !== 'None';

  const canShowPreApprovalTags = supportsPreApproval && !isRequote && !isFinanceApplication;

  const standardQuote = getQuoteDisplaySchema({
    quote: { ...quote, LoanType: LoanType.standardLoan },
    options: { variant, isBnpp: uiState.isBnpp }
  });
  const headerFigures = getHeaderFigures(quote, standardQuote);

  let multiLoans = null;
  if (hasCompanionQuotes) {
    multiLoans = getAllLoansDisplaySchema({
      quote,
      options: { variant, isBnpp: uiState.isBnpp }
    });
  }
  const showMultiLoans = multiLoans?.Vehicle && (multiLoans.NegativeEquity || multiLoans.ValueAddedProduct);
  const funder = funders && (funders.find((funder: FunderType) => funder.Code === quote.FunderCode) as FunderType);

  const handleProceed = () => {
    if (isCombinedQuote) {
      actions?.onProceedToBreakDown && actions.onProceedToBreakDown(quote);
    } else {
      actions.onProceed && actions.onProceed({ ...quote, ...vehicle });
    }
  };

  const handleSave = () => {
    actions?.onSave && actions.onSave(quote);
  };

  const handleProductNameClick = () => {
    const productId = getVehicleLoanProductId(quote);
    actions?.onProductNameClick && actions.onProductNameClick(productId);
  };

  const handleViewApplication = (isMultiQuote?: boolean) => {
    actions?.viewApplication && application && actions.viewApplication(application, isMultiQuote);
  };

  const handleEditApplication = () => {
    actions?.editApplication && application?.ApplicantId && actions.editApplication(application.ApplicantId);
  };

  const handleEligibilityClick = () => {
    actions?.onEligibilityMatchClick && actions.onEligibilityMatchClick(quote.MatchRate, quote.DecisionMessages);
  };

  if (quote.Errors) {
    return (
      <div className="quoteCard" data-thook={trackingPage}>
        <div className="quoteCard__erroredQuote">
          <div className="quoteCard__errorLogoProduct">
            <div className="quoteCard__logo">
              <FunderLogo funder={quote.FunderCode} />
            </div>

            {quote.ProductName && (
              <div>
                <div className="quoteCard__infoTitle">{t('QuoteCardHeader.product')}</div>

                <div className="quoteCard__quoteInfo--erroredName quoteCard__quoteInfo--productName">
                  <div data-th="ProductName">
                    <span className="quoteCard__productName">{quote.ProductName}</span>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="quoteCard__errorList">
            {quote.Errors.map((error: { Number: number; Message: string }, index: number) => (
              <ErroredQuote errorNumber={error.Number} errorMessage={error.Message} key={index} noBorder />
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <section
      className={cn('quoteCard', {
        'quoteCard--withHover': actions?.onQuoteSelect && (isFinanceQuote || isSendDeal),
        'quoteCard--withCheckbox': actions?.onQuoteSelect && (isFinanceQuote || isSendDeal),
        'quoteCard--isSelected': quote.isChecked,
        'quoteCard--withTag':
          application?.isSelfService || (application?.CreatedUtc && application?.Tags?.multiQuoteRefId)
      })}
      data-thook={trackingPage}
    >
      {(isFinanceQuote || isSendDeal) && actions?.onQuoteSelect && (
        <div className="quoteCard__checkbox">
          <Checkbox
            id={'compare' + quote.QuoteId}
            onChange={(checked: boolean) => {
              actions?.onQuoteSelect && actions.onQuoteSelect(quote.QuoteId, checked);
            }}
            value={quote.isChecked}
          />
        </div>
      )}

      {canShowPreApprovalTags && (
        <div className="quoteCard__tags">
          <Tag
            dataTh="quoteCard__tags--soft-search"
            text={t('QuoteCardTags.soft_check')}
            startIconName="information"
            level="info"
            bold={true}
          />
          <Tag
            dataTh="quoteCard__tags--pre-approval"
            text={t('QuoteCardTags.pre_approval_available')}
            startIconName="validation-tick"
            level="success"
          />
        </div>
      )}

      {/* QUOTECARD UPPER:  logo, applicant name, product name, main highlighted figures
 and variant-specific extras e.g application status, eligibility status */}
      <div className="quoteCard__upper">
        {isFinanceApplication && application?.isSelfService && <SelfServicedApplicationTag position="customerPage" />}
        {isFinanceApplication && application?.CreatedUtc && application?.Tags?.multiQuoteRefId && (
          <div className="quoteCard__dealStickerContainer">
            <div className="quoteCard__dealSticker">
              {t('QuoteCard.part_of_a_deal_created')}{' '}
              <DateFormat value={application?.CreatedUtc} format="DD/MM/YYYY - HH:mm" />
            </div>
          </div>
        )}

        <header className="quoteCard__header">
          <div className="quoteCard__logo">
            <FunderLogo funder={quote.FunderCode} />
          </div>

          <div className="quoteCard__infoWrap">
            {isFinanceApplication && applicantName && (
              <div className="quoteCard__info">
                <div className="quoteCard__infoTitle">{t('QuoteCardHeader.applicant')}</div>
                <div>{applicantName}</div>
              </div>
            )}

            {quote.ProductName && (
              <div className="quoteCard__info">
                <div className="quoteCard__infoTitle">{t('QuoteCardHeader.product')}</div>

                <div data-th="ProductName">
                  {actions?.onProductNameClick && !isBdk && !isRequote ? (
                    <span
                      className="quoteCard__productName quoteCard__productName--action"
                      role="button"
                      onClick={handleProductNameClick}
                    >
                      {quote.ProductName}
                    </span>
                  ) : (
                    <span>{quote.ProductName}</span>
                  )}
                </div>
              </div>
            )}
          </div>
        </header>

        <dl className="quoteCard__figures">
          <HeaderFigures
            data={headerFigures}
            canShowIndicativeAprTag={canShowIndicativeAprTag}
            canShowApplicationStatus={canShowApplicationStatus}
          />

          {canShowApplicationStatus && (
            <ApplicationStatus
              status={
                lenderStatus && isFinanceApplication ? (lenderStatus.replace(/\s+/g, '') as StatusType) : undefined
              }
              matchRate={quote?.MatchRate}
              personalScorePercentage={financeCheck?.PersonalScorePercentage}
              additionalClasses="quoteCard__cell quoteCard__cell--withApplicationStatus quoteCard__applicationStatus--inline"
              handleClick={actions?.onEligibilityMatchClick && handleEligibilityClick}
            />
          )}

          {canShowPreApprovalAvailable && (
            <div
              className="quoteCard__cell quoteCard__cell--pre-approval"
              data-th="quoteCard__figures--pre_approval_available"
            >
              <span className="quoteCard__cellValue">{t('QuoteCardFigures.pre_approval_available')}</span>
            </div>
          )}
        </dl>

        <div className="quoteCard__statusButtons">
          {(isFinanceApplication || isSavedQuote) && (application?.LastModifiedTimestamp || application?.SavedTime) && (
            <p className="quoteCard__statusDate">
              <strong>{isSavedQuote ? 'Date Saved' : 'Status Date'}: </strong>
              <DateFormat
                value={isSavedQuote ? application.SavedTime : application.LastModifiedTimestamp}
                format="DD MMM YYYY HH:mm"
                parse="utc"
              />
            </p>
          )}

          {canShowApplicationStatus && (
            <ApplicationStatus
              status={
                lenderStatus && isFinanceApplication ? (lenderStatus.replace(/\s+/g, '') as StatusType) : undefined
              }
              matchRate={quote?.MatchRate}
              personalScorePercentage={financeCheck?.PersonalScorePercentage}
              additionalClasses="quoteCard__cell--withApplicationStatus quoteCard__applicationStatus--block"
              handleClick={actions?.onEligibilityMatchClick && handleEligibilityClick}
            />
          )}

          <div className="quoteCard__buttonWrap">
            <QuoteButton
              variant={variant}
              canViewConsumerApplications={canViewConsumerApplications}
              isCombinedQuote={isCombinedQuote}
              financeCheck={financeCheck}
              isQuickQuote={isQuickQuote}
              vehicleClass={vehicleClass}
              isCustomerQuote={isCustomerQuote}
              canCreateApplications={canCreateApplications}
              chosenQuoteId={chosenQuoteId}
              dealershipId={dealershipId}
              consumerId={consumerId}
              vehicleId={vehicleId}
              quoteId={quote.QuoteId}
              multiQuoteRefId={application?.Tags?.multiQuoteRefId}
              financeApplications={financeApplications}
              proceedingState={proceedingState}
              canGenerateQuotes={canGenerateQuotes}
              lenderStatus={lenderStatus}
              handleProceed={handleProceed}
              handleSave={handleSave}
              handleView={isSavedQuote || isCarFinanceCheck ? actions?.viewApplication : handleViewApplication}
              handleEdit={handleEditApplication}
              financeType={quote.FinanceType || ''}
              supportsPreApproval={quote.SupportsPreApproval}
            />
          </div>
        </div>
      </div>

      {/* QUOTECARD EXPANDABLE AREA: main quote details table
 and if multi-schedule, companion quote details tables  */}
      <section className={cn('quoteCard__collapsibleTable', { 'quoteCard__collapsibleTable--isHidden': !isExpanded })}>
        {standardQuote && (
          <DetailsTable
            data={standardQuote}
            title={t('QuoteCardTable.personalised_quote')}
            loanType={LoanType.standardLoan}
          />
        )}

        {showMultiLoans && (
          <div className="quoteCard__multiLoans">
            <MultiLoansContainer loans={multiLoans!} toggleMultiScheduleComponents={toggleMultiScheduleComponents} />
          </div>
        )}

        {/* QUOTECARD FOOTER: lender-specific finance text, disclaimers, schemes, notes
 for customer e.g is combined quote, is risk based pricing quote  */}
        <footer className="quoteCard__tableFooter">
          {quote.ConsumerWarnings && quote.ConsumerWarnings.hasPublicWarnings && (
            <PublicWarningMessage publicWarnings={quote.ConsumerWarnings.PublicWarnings} />
          )}
          {newCarOffer?.OfferText && <p>{newCarOffer.OfferText}</p>}

          <QuoteDisclaimer funder={funder} isBdk={isBdk} isPreApproved={isPreApprovedFinanceApplication || isRequote} />

          {isRiskBasedPricingQuote && (
            <p>
              <strong>{t('QuoteCardSmallPrint.this_apr_is_based_on_the_customers_credit_rating')}</strong>
            </p>
          )}

          {schemesVisible && quote.Commissions && (
            <p className="quoteCard__schemes">
              <strong>
                {t('QuoteCard.schemes')}: <CommissionValue commissions={quote.Commissions} />
              </strong>
            </p>
          )}

          {isCombinedQuote && actions.onCombinedQuoteClick && (
            <CombinedQuoteMessage
              numberOfIcons={quote.PersonalLoan && quote.NegativeEquityLoan ? 3 : 2}
              onClick={() => {
                actions?.onCombinedQuoteClick && actions.onCombinedQuoteClick(quote);
              }}
              colour="grey"
            />
          )}
        </footer>
      </section>

      <div className="quoteCard__tableButton">
        <FieldToggle
          text={t('QuoteCardTable.full_quote')}
          onClick={() => setIsExpanded(!isExpanded)}
          showHide={isExpanded}
          trackingPage={trackingPage}
          trackingItem={'IndividualQuote'}
        />
      </div>
    </section>
  );
};

export interface QuoteCardProps {
  variant: QuoteCardVariantType;
  quote: any;
  application?: any;
  appStore?: any;
  financeCheck?: any;
  funders: any;
  vehicle: any;
  actions: ButtonActionsProps;
  showFullQuote?: boolean;
  trackingPage: string;
  schemesVisible?: boolean;
  isQuickQuote: boolean;
  vehicleClass: string;
  isCustomerQuote: boolean;
  chosenQuoteId: string;
  QuoteId: string;
  proceedingState: any;
  newCarOffer?: any;
  applicantName?: string;
  lenderStatus?: string;
  financeApplications?: any;
  hasCheckedEligibility?: boolean;
  dealershipId?: string;
  consumerId?: string;
  vehicleId?: string;
  toggleMultiScheduleComponents: (isToggled: boolean) => void;
}

interface ButtonActionsProps {
  onSave?: (quote: object) => void;
  onProceed?: (quote: object) => void;
  onProceedToBreakDown?: (quote: object) => void;
  onProductNameClick?: (productId: string) => void;
  onQuoteSelect?: (productId: string, checked: boolean) => void;
  onCombinedQuoteClick?: (quote: object) => void;
  viewApplication?: (...args: any) => void;
  editApplication?: (application: object) => void;
  onEligibilityMatchClick?: (...args: any) => void;
}

function mapStateToProps(state: any) {
  return {
    funders: state.funders,
    vehicle: state.quotes.vehicle
  };
}

export default connect(mapStateToProps)(inject('appStore')(QuoteCard));
