import { useTranslation } from 'react-i18next';
import { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Page from '../../Common/Page';
import Breadcrumbs from '../../Common/Breadcrumbs';
import Panel from '../../Common/Panel';
import AddressLine from '../../Common/AddressLine';
import VehicleDetailsSidebar from '../../PushDeal/VehicleDetailsSidebar';
import ActivityTimeline from '../../PushDeal/ActivityTimeline';
import ActivityTimelineItem from '../../PushDeal/ActivityTimelineItem';
import PanelHeader from '../../Common/PanelHeader';
import Icon from '../../Common/Icon/Icon';
import Button from '../../Common/Button/Button';
import TrackEvent from '../../Common/Tracking/TrackEvent';
import OrderSummaryTable from './OrderSummaryTable';
import ActivityTimelineContent from '../../PushDeal/ActivityTimelineContent';
import LoadingDots from '../../Common/Loading/LoadingDots';
import AlertCard from '~Common/AlertCard/AlertCard';
import { getOrder } from '../../../api/orders';
import { getClientApp } from '../../../core/helpers';
import { compose } from 'redux';
import { useNavigate } from 'hooks/useNavigate';
import { withQuery } from '../../../hocs/router';
import { useParams } from 'react-router-dom';
import { inject } from 'mobx-react';
import { printPageByRef } from '~/core/print';
import { canSeeRekoSchutzInterestAlert } from '~/features';
import './orderSummary.scss';

function OrderSummary(props) {
  const { t } = useTranslation('DigitalDeals');
  const params = useParams();
  const navigate = useNavigate();

  const getBreadcrumbs = (customerId, vehicleId = '') => {
    let crumbs = [
      {
        name: t('OrderSummary.home'),
        path: `/d/${params.dealershipId}`,
        isHome: true
      }
    ];

    if (props.query.ref !== 'customer') {
      crumbs.push({
        name: t('OrderSummary.orders_deals'),
        path: `/d/${params.dealershipId}/orders-and-deals`,
        query: {
          page: 1,
          pageSize: 5,
          dealStatus: 'all',
          sortBy: 'sentAt'
        }
      });
    }

    if (props.query.ref === 'customer') {
      crumbs.push({
        name: t('OrderSummary.consumer'),
        path: `/d/${params.dealershipId}/consumers/${customerId}`,
        query: {
          vehicleId: vehicleId
        }
      });
    }

    crumbs.push({
      name: t('OrderSummary.order_summary')
    });
    return crumbs;
  };

  const [data, setData] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    async function getData() {
      const response = await getOrder(params.dealershipId, params.orderId);
      setData(response);
    }

    getData();
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (data) {
      async function getCustomer() {
        const response = await props.appStore.customerStore.fetchCustomerData(customerId, params.vehicleId);
        setCustomer(response);
      }

      getCustomer();
    }
  }, [data]);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1024);
  };

  const onSendDeal = () => {
    const vehicleForDeal = {
      CapId: vehicle.CapId,
      Class: vehicle.Class?.toLowerCase() || '',
      Condition: vehicle.Condition || '',
      Vrm: vehicle.Vrm,
      Make: vehicle.Make,
      Model: vehicle.Model,
      Derivative: vehicle.Derivative,
      Mileage: vehicle.Mileage,
      CdnVehicleImageUrl: vehicle.VehicleImageUrls || null,
      RegistrationDate: vehicle.RegDate ? moment(vehicle.RegDate).format('DD/MM/YYYY') : '',
      VehicleId: vehicle.VehicleId
    };
    let actualDeposit = props.preferredDeposit;
    let annualDistance = props.preferredAnnualMileage;
    let term = props.preferredTerm;

    if (application && application.Quote) {
      actualDeposit = application.Quote.ActualCashDeposit || application.Quote.Deposit || props.preferredDeposit;
      annualDistance = application.Quote.AnnualDistanceQuoted || props.preferredAnnualMileage;
      term = application.Quote.Term || props.preferredTerm;
    }

    props.appStore.pushDealStore.setVehicle(
      vehicleForDeal,
      vehicle.CashPrice, //does this need to be - vapsTotal (yes because it's from NV, CashPrice is TotalPrice), CashPrice is vehicle price
      actualDeposit,
      annualDistance,
      term,
      params.dealershipId
    );

    if (application && application.Quote) {
      props.appStore.pushDealStore.updatePartExSettlement(
        application.Quote.PartExchange,
        application.Quote.OutstandingSettlement
      );
    } else if (data.Order.PartExchanges && data.Order.PartExchanges.length > 0) {
      let partExTotal = data.Order.PartExchanges.reduce((acc, partEx) => acc + partEx.EstimatedValue, 0);
      let settlementTotal = data.Order.PartExchanges.reduce((acc, partEx) => acc + partEx.OutstandingFinance, 0);
      props.appStore.pushDealStore.updatePartExSettlement(partExTotal, settlementTotal);
    }

    navigate({
      pathname: `/d/${params.dealershipId}/send-deal`,
      query: {
        consumerId: customerId,
        entry: 'with-finance'
      }
    });
  };

  const goToCustomerRecord = () => {
    navigate({
      pathname: `/d/${params.dealershipId}/consumers/${customerId}`,
      query: {
        vehicleId: vehicle.vehicleId
      }
    });
  };

  const goToCustomerApplication = (applicationId, notSubmitted, goToStatus) => {
    let page;

    if (notSubmitted) {
      page = 'applicationsummary';
    } else {
      if (goToStatus) {
        page = 'status';
      } else {
        page = 'viewapplicationsummary';
      }
    }

    navigate({
      pathname: `/d/${params.dealershipId}/consumers/${customerId}/application/${applicationId}/${page}/`,
      query: {
        order: params.orderId
      }
    });
  };

  const parseVehicleDetails = (order, customer) => {
    if (!order) {
      return {};
    }
    const VehicleDetails = order.VehicleDetails;
    const customerVehicle = customer?.Vehicles.find((v) => v.VehicleId === order.VehicleId);

    return {
      VehicleImageUrls: VehicleDetails.CdnVehicleImageUrl,
      NewVehicle: VehicleDetails.NewVehicle,
      CapCode: VehicleDetails.VehicleCapCode,
      CapId: VehicleDetails.VehicleCapId,
      CashPrice: VehicleDetails.VehicleCashPrice,
      Class: VehicleDetails.VehicleClass || customerVehicle?.Class || '',
      Condition: VehicleDetails.VehicleCondition || customerVehicle?.Condition || '',
      DateDiscontinued: VehicleDetails.VehicleDateDiscontinued,
      DateIntroduced: VehicleDetails.VehicleDateIntroduced,
      Derivative: VehicleDetails.VehicleDerivative,
      Doors: VehicleDetails.VehicleDoors,
      DriveTrain: VehicleDetails.VehicleDriveTrain,
      FuelDelivery: VehicleDetails.VehicleFuelDelivery,
      FuelType: VehicleDetails.VehicleFuelType,
      GlassModelCode: VehicleDetails.VehicleGlassModelCode,
      GlassModelId: VehicleDetails.VehicleGlassModelId,
      GlassQualifyId: VehicleDetails.VehicleGlassQualifyId,
      Make: VehicleDetails.VehicleMake,
      Manufacturer: VehicleDetails.VehicleManufacturer,
      Mileage: VehicleDetails.VehicleMileage,
      Model: VehicleDetails.VehicleModel,
      Range: VehicleDetails.VehicleRange,
      RegDate: VehicleDetails.VehicleRegDate,
      RegDateTimestamp: VehicleDetails.VehicleRegDateTimestamp,
      Sector: VehicleDetails.VehicleSector,
      Source: VehicleDetails.VehicleSource,
      StockIdentity: VehicleDetails.VehicleStockIdentity,
      StockLocation: VehicleDetails.VehicleStockLocation,
      Transmission: VehicleDetails.VehicleTransmission,
      Trim: VehicleDetails.VehicleTrim,
      VehicleId: order.VehicleOfInterestId,
      Vrm: VehicleDetails.VehicleVrm
    };
  };
  const printAreaRef = useRef();

  const handlePrint = () => {
    printPageByRef(printAreaRef);
  };

  const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? <div className="orderSummary__container"> {children} </div> : children;

  const order = data && data.Order;
  const application = data && data.Application;
  const quote = (data && data.Application && data.Application.Quote) || order?.Quote;
  const vehicle = parseVehicleDetails(order, customer);
  const customerId = application ? application.CustomerId : order && order.DealershipConsumerId;
  const notSubmitted =
    (application && application.Status === 'Not Submitted') ||
    (application && application.Status === 'Awaiting submission to lender') ||
    (application && application.Status === null);
  const customerContactNumber = order && (order.CustomerHomeTelephone || order.CustomerMobileTelephone);
  let customerAddress =
    order && order.PaymentMethod === 'Cash'
      ? order && order.CustomerAddress
      : (application && application.AddressHistory[0]) || order?.CustomerAddress;
  const noAddress = !customerAddress;
  const clientApp = application && getClientApp(application);
  const showIddViewedActivityItem =
    order && order.AcceptedInitialDisclosureDocument && application?.ClientApp === 'new-vehicle-account';

  return (
    <Page>
      {!order ? (
        <div className="applicationStatus__status">
          <LoadingDots />
        </div>
      ) : (
        <>
          {customer && customerId && order ? (
            <Breadcrumbs items={getBreadcrumbs(customerId, order.VehicleOfInterestId)} consumer={customer} />
          ) : (
            <Breadcrumbs
              items={[
                {
                  name: t('OrderSummary.order_summary')
                }
              ]}
            />
          )}
          <div className="orderSummaryActivityContainer">
            <div className="orderSummary__dealSummary">
              <Panel>
                {vehicle && (
                  <VehicleDetailsSidebar
                    currentDeal={{
                      vehicleImageUrls: [vehicle.VehicleImageUrls],
                      vehicle: {
                        make: vehicle.Make,
                        model: vehicle.Model,
                        class: vehicle.Class,
                        vrm: vehicle.Vrm
                      }
                    }}
                    vehiclePrice={vehicle.CashPrice}
                  />
                )}
              </Panel>
            </div>
            <div className="orderSummary__dealActivity">
              {order?.NewVehicleCustomerUIOptions?.hasRekoSchutzSelected === 'true' && canSeeRekoSchutzInterestAlert() && (
                <div className="orderSummary__headerAlert">
                  <AlertCard
                    iconName="information"
                    title={t('OrderSummary.reko_schutz')}
                    paragraph={t('OrderSummary.customer_interested_in_reko_schutz_please_add_to_the_quote')}
                  />
                </div>
              )}
              <Panel>
                <div className="orderSummary__activity">
                  <PanelHeader>
                    <div className="orderSummary__header">
                      <span>{t('OrderSummary.order_summary')}</span>
                      <div className="orderSummary__headerButton">
                        {props.appStore.uiState.canUsePushDeal && props.appStore.uiState.canGenerateQuotes && (
                          <TrackEvent
                            featureName="orderSummary"
                            interactionName="orderSummary__linkFromDealBreakdown"
                            linkTo={`/d/${params.dealershipId}/send-deal?consumerId=${params.consumerId}`}
                          >
                            <Button onClick={onSendDeal}>{t('OrderSummary.send_deal')}</Button>
                          </TrackEvent>
                        )}
                      </div>
                    </div>
                  </PanelHeader>
                  {order && (
                    <div className="orderSummary__activityTimeline">
                      <div className="orderSummary__customerDetails">
                        <ConditionalWrapper condition={!isMobile}>
                          <div className="orderSummary__customerDetails_name">
                            <div className="orderSummary__icon">
                              <Icon name="consumer" />
                            </div>
                            <div className="orderSummary__customerDetails_link" onClick={goToCustomerRecord}>
                              {`${order.CustomerFirstName} ${order.CustomerSurname}`}
                            </div>
                          </div>
                          {!isMobile && <div className="orderSummary__divider" />}
                          <div className="orderSummary__customerDetails_phoneNumber">
                            <a className="orderSummary__customerDetails_Link" href={`tel:${customerContactNumber}`}>
                              {customerContactNumber}
                            </a>
                          </div>
                          {!isMobile && <div className="orderSummary__divider" />}
                          <div className="orderSummary__customerDetails_email">
                            <a
                              href={`mailto:${order.CustomerEmail}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              data-thook="customerEmail"
                              className="orderSummary__customerDetails_emailLink"
                            >
                              {order.CustomerEmail}
                            </a>
                          </div>
                        </ConditionalWrapper>
                        <div className="orderSummary__customerDetails_address">
                          <AddressLine address={customerAddress} noAddress={noAddress} />
                        </div>
                      </div>
                      {order.PaymentMethod === 'Finance' && (
                        <ActivityTimeline>
                          {application && (
                            <ActivityTimelineItem
                              id="applicationSubmitted"
                              title={
                                application.Status === null
                                  ? t('OrderSummary.awaiting_submission_to_lender')
                                  : t('OrderSummary.submitted_to_lender')
                              }
                              isOrder={true}
                              showInProgress={application.Status === null}
                            >
                              <ActivityTimelineContent date={application.CreatedDate}>
                                <ul className="orderSummary__activityTimelineList">
                                  <li className="orderSummary__activityTimelineItem">
                                    {t('OrderSummary.customer_completed_message', {
                                      FunderName: application.Quote.FunderName,
                                      clientApp: clientApp
                                    })}
                                  </li>
                                  {showIddViewedActivityItem && (
                                    <li className="orderSummary__activityTimelineItem">
                                      {t('OrderSummary.customer_acknowledge_message', {
                                        clientApp: clientApp
                                      })}
                                    </li>
                                  )}
                                </ul>
                              </ActivityTimelineContent>
                              <div className="orderSummary__buttonOuter">
                                <Button
                                  className="orderSummary__viewApplicationButton"
                                  buttonStyle="secondary"
                                  onClick={() => goToCustomerApplication(application.Id, notSubmitted, true)}
                                >
                                  {t('OrderSummary.view')}{' '}
                                  {notSubmitted
                                    ? t('OrderSummary.application_and_submit')
                                    : t('OrderSummary.application_status')}
                                </Button>
                              </div>
                            </ActivityTimelineItem>
                          )}
                        </ActivityTimeline>
                      )}
                      <div className="printArea" ref={printAreaRef}>
                        <OrderSummaryTable
                          vehicle={vehicle}
                          order={order}
                          quote={quote}
                          customerAddress={customerAddress}
                          goToCustomerApplication={
                            application ? () => goToCustomerApplication(application.Id, notSubmitted, false) : null
                          }
                        />
                        {order.GeneralEnquiryMessage && (
                          <div className="orderSummary__details">
                            <div className="orderSummaryTable__header">
                              <div className="orderSummaryTable__title">{t('OrderSummaryTable.customer_message')}</div>
                            </div>
                            <div className="orderSummaryTable__section">
                              <div className="orderSummaryTable__content">{order.GeneralEnquiryMessage}</div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="orderSummary__printButton">
                        <Button buttonStyle="secondary" onClick={handlePrint}>
                          {t('OrderSummary.print')}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </Panel>
            </div>
          </div>
        </>
      )}
    </Page>
  );
}

OrderSummary.propTypes = {
  params: PropTypes.object,
  query: PropTypes.object
};
export default compose(withQuery, inject('appStore'))(OrderSummary);
